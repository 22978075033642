import React, { type FC } from 'react'
import {ContentExcel} from "./style"
import PropTypes from "./interface"
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';

const ThemeExcelAddIn: FC<PropTypes> = (props) => {
    const {children} = props
    const theme = useAppSelector(currentTheme);
    return(
        <ContentExcel theme={theme}>
            {children}
        </ContentExcel>
    )
}

export default ThemeExcelAddIn;


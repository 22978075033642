import { Hooks, useAuthContext } from "@asgardeo/auth-react";
import AtomicLoader from "./atom/loader"
import Header from "./components/header"
import MenuLeft from "./components/menuLeft"
import AppLayout from "./components/appLayout"
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/raleway/300.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/500.css';
import '@fontsource/raleway/700.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { routes } from './routes'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "react-datepicker/dist/react-datepicker.css";
import { ThemeDrawer } from './widgets'
import GlobalStyles from './GlobalStyles'
import { FinancialDrawer, RealtimeProvider, useRealtime } from 'fs-widgets-core';
import { useEffect, useState } from "react";
//@ts-ignore
import SockJsClient from 'react-stomp';
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { currentTheme } from './theme/hooks/themeSlice';
import { getLangs } from "./lang/langSlice";

import { default as config } from './config.json'
import Loader from "./atom/loader";
import { getUser, setUser } from "./trader/traderSlice";
import TraderUserModel from "./trader/interface/traderUser.model";
import axios from 'axios';
import { httpClient } from "fs-http-core";

const theme = createTheme({
  palette: {
    background: {
      paper: '#001865',
    }
  },
});

const App = () => {


  const dispatch = useAppDispatch();
  const { symbols, streamMessage, configureAxiosInstance } = useRealtime();
  const [derivedAuthenticationState, setDerivedAuthenticationState] = useState<any>(null);
  const [hasAuthenticationErrors, setHasAuthenticationErrors] = useState<boolean>(false);
  const [hasLogoutFailureError, setHasLogoutFailureError] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();
  const search = useLocation().search;
  const stateParam = new URLSearchParams(search).get('state');
  const errorDescParam = new URLSearchParams(search).get('error_description');

  const {
    state,
    signIn,
    getBasicUserInfo,
    getIDToken,
    getDecodedIDToken,
    getAccessToken,
    error,
    on
  } = useAuthContext();

  useEffect(() => {
    on(Hooks.SignOut, () => {
      setHasLogoutFailureError(false);
    });

    on(Hooks.SignOutFailed, () => {
      if (!errorDescParam) {
        handleLogin();
      }
    })
  }, [on]);

  const handleLogin = () => {
    setHasLogoutFailureError(false);
    signIn()
      .catch(() => setHasAuthenticationErrors(true));
  };

  useEffect(() => {
    if (stateParam && errorDescParam) {
      if (errorDescParam === "End User denied the logout request") {
        setHasLogoutFailureError(true);
      }
    }
  }, [stateParam, errorDescParam]);

  useEffect(() => {

    if (!state?.isAuthenticated) {
      return;
    }

    (async (): Promise<void> => {
      const basicUserInfo = await getBasicUserInfo();
      const idToken = await getIDToken();
      const decodedIDToken = await getDecodedIDToken();
      const accessToken = await getAccessToken();
      const derivedState = {
        authenticateResponse: basicUserInfo,
        idToken: idToken,
        decodedIdTokenHeader: JSON.parse(atob(idToken.split(".")[0])),
        decodedIDTokenPayload: decodedIDToken
      };
      // console.log('SESSION', derivedState);
      setDerivedAuthenticationState(derivedState);
      // console.log('basicUserInfo', basicUserInfo);
      // const req = {
      //   email: derivedState.authenticateResponse.email,
      //   firstName: derivedState.authenticateResponse.givenName,
      //   lastName: derivedState.authenticateResponse.displayName,
      //   userId: derivedState.authenticateResponse.sub
      // }
      const req = {
        accessToken: idToken
      }

      //authenticated with keycloak
      const response = await axios.post(`keycloak/rest/api/auth/c-token-exchange`, req)
      if (response.data && response.data.entity && response.data.entity.access_token) {

        const authToken = response.data.entity.access_token
        const { access_token, refresh_token } = response?.data?.entity;
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
        //axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;

        const user: any = await httpClient.get(`${process.env.REACT_APP_CONFIG_SERVICES}/api/account`)
        configureAxiosInstance(
          {
            headers: {
              'Authorization': `Bearer ${authToken}`
            }
          }
        )
        dispatch(setUser({
          alias: String(derivedState.authenticateResponse.sub?.replace("#","@")),
          id: user.data.id,
        }));
        setIsLoading(false);
        dispatch(getLangs());
      }
    })();
  }, [state.isAuthenticated]);

  const onMessage = (msg: any) => {
    streamMessage(msg);
  }

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!state.isAuthenticated) {
      handleLogin();
    }
  }, []);

  useEffect(
    () => {
      if(location.pathname === '/logout'){
        window.location.href = process.env.REACT_APP_SIGNOUT_REDIRECT || '';
      }
      console.log('hasLogoutFailureError', hasLogoutFailureError)
      if (hasLogoutFailureError) {
        handleLogin();
      }
    }, [hasLogoutFailureError]
  )
  const themeComponent = useAppSelector(currentTheme);


  if (isLoading) {
    return <AtomicLoader loading loop />
  }

  if (hasAuthenticationErrors) {
    return <h2>Ocurrió un error al iniciar sesión</h2>
  }

  return (

    <ThemeProvider theme={theme}>
      <GlobalStyles theme={themeComponent} />
      <SockJsClient
        key={'realtime-broker'}
        url={process.env.REACT_APP_SOCKET_URL}
        onMessage={(message: any) => onMessage(message)}
        topics={symbols.map((topic: string) => `/topic/${topic}`)}
      >
      </SockJsClient>
      <AppLayout drawerOpen={open}>
        <AtomicLoader loading={false} loop />
        <Header />
        <MenuLeft />
        {/* CONTENT */}
        <div className='wrapper'>
          <Routes>
            {
              routes.map(
                (route: any) => (
                  <Route path={route.path} element={route.component} />
                )
              )
            }
          </Routes>
        </div>
        <ThemeDrawer>
          <FinancialDrawer
            open={open}
            title='Configuración'
          />
        </ThemeDrawer>
      </AppLayout>
    </ThemeProvider>
  );
}

export default App;

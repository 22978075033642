import { httpClient } from 'fs-http-core';
import ContractModel from './interface/contract.model';
import StockPortfolioModel from './interface/stockPortfolio.model';
import TraderUserModel from './interface/traderUser.model';
import TransferRequestModel, { TransferResponseModel } from './interface/transfer.model';
import FundListItem, { mapToFundListItem } from './interface/fundListItem.model';
import { decimalFmt } from '../utils';

const urlBase = process.env.REACT_APP_TRADER_SERVICES;

const getContractsService = async (alias: string) => {
  const response = await httpClient.get(`${urlBase}/contracts/${alias}`);
  if (Array.isArray(response?.data?.contratos)) {
    return (response.data.contratos as any[])
      .map(item => ({
        id: item.id,
        name: item.nombre,
        terms: item.BTerminos,
        default: item.default,
      })) as ContractModel[];
  }
  return [] as ContractModel[];
}

const getStockPortfolioService = async (alias: string, contractId: string, rawData: boolean = false) => {
  try {
    const response = await httpClient.post(`${urlBase}/get-stock-portfolio`, {
      alias,
      contractId,
    });
    const { data } = response;
    const { clasificacion } = data;
    if(rawData) {
      return response.data;
    }
    return {
      portfolioPct: data?.porcentajeCartera,
      value: data?.valorCartera,
      uniPortfolio: data?.uniCartera,
      clasification: {
        equities: clasificacion?.capitales,
        cash: clasificacion?.efectivo,
        money: clasificacion?.dinero,
        funds: clasificacion?.fondos,
        equitiesPct: clasificacion?.pctCapitales,
        cashPct: clasificacion?.pctEfectivo,
        moneyPct: clasificacion?.pctDinero,
        fundsPct: clasificacion?.pctFondos,
      }
    } as StockPortfolioModel;
  } catch (e) {
    console.error('ERROR GET PORTFOLIO', e)
    return new Promise<StockPortfolioModel>(resolve => {
      resolve({
        clasification: null,
        portfolioPct: null,
        value: null,
        uniPortfolio: null
      });
    })
  }

}

const getPortfolioDetailService = async (alias: string, contractId: string) => {
  try {
    const response = await httpClient.post(`${urlBase}/get-portfolio-detail`, {
      alias,
      contractId,
    });
    return response;
  } catch (e: any) {
    if(e?.response){
      return e.response;
    }
    return null;
  }
}

const getPortfolioHistoryService = async (alias: string, contractId: string) => {
  try {
    const response = await httpClient.post(`${urlBase}/get-portfolio-history`, {
      alias,
      contractId,
    });
    return response;
  } catch (e: any) {
    if(e?.response) {
      return e.response;
    }
    return null;
  }
}

const getTransactionsService = async (alias: string, contractId: string, payload: any) => {
  try {
    const response = await httpClient.post(`${urlBase}/get-transactions/${alias}/${contractId}`, payload);
    return response;
  } catch (e: any) {
    if(e?.response) {
      return e.response;
    }
    return null;
  }
}

const getListFundsService = async (alias?: string, contractId?: string): Promise<FundListItem[]> => {
  if (alias && contractId) {
    const response = await httpClient.get(`${process.env.REACT_APP_TRADER_SERVICES}/list-funds/${alias}/${contractId}`)
    if (Array.isArray(response.data?.fondos)) {
      return response.data.fondos.map((item: any) => mapToFundListItem(item));
    }
  }  
  return [] as FundListItem[];
}

const getUserService = () => {
  const user: TraderUserModel = {
    alias: 'RAMONLOPEZANGELES@HOTMAIL.COM', //RAMONLOPEZANGELES@HOTMAIL.COM
    id: '10'
  };
  return Promise.resolve(user);
}

const getInfoTermsService = async (alias: string) => {
  const response = await httpClient.get(`${urlBase}/contracts/terms/${alias}`)
  if (response.data?.documento) {
    return response.data.documento as string;
  }
  return null;
}

const updateTermsService = async (alias: string, contractId: string) => {
  const request = {
    "bTerminos": "SI",
    "contrato": contractId
  }
  const response = await httpClient.post(`${urlBase}/contracts/update/${alias}`, request);
  return response.data;
} 

const getBusinessDays = async () => {
  const response = await httpClient.get(`${urlBase}/business-days`);
  if (Array.isArray(response.data?.diasHabiles)) {
    return response.data.diasHabiles as string[];
  }
  return [];
}

const makeTransferService = async (payload: TransferRequestModel) => {
  try {
    const request: TransferRequestModel = {
      ...payload,
      idContrato: Number(payload.idContrato),
      impMovimiento: Number((payload.impMovimiento as string).replace(/,/g, '')),
      idPersonaRegistra: Number(payload.idContrato)
    };
    const response = await httpClient.post(`${urlBase}/payment/digital-signature`, request);
    return response.data as TransferResponseModel;
  } catch (e: any) {
    if (e?.response) {
      console.error('ERROR: ', e.response?.status)
      console.error('ERROR: ', e.response?.data)
      return e.response?.data as TransferResponseModel;
    }
    return null;
  }

}

const getStatusFund = (item: any) => {
  switch (item.sitOrden) {
    case 'AS': return 'Asignada';
    case 'CA': return 'Cancelada';
    case 'OR': return 'Ordenada';
    default: return item.sitOrden;
  }
}
const dataFundsMap = (item: any, idx: number): any => {
  return ({
    field_date: item.fechaRegistro,
    field_time: item.horaRegistro,
    field_folio: item.folio,
    field_name: item.emisora,
    field_type: item.operacion,
    field_titles: item.numTitulosAsign,
    field_asign_titles: item.numTitulosAsign,
    field_price: (item.precioAsignacion),
    field_prom_price:   (item.importeOperacion),
    field_validity: item.date,
    field_status: getStatusFund(item),
    field_serie: item.serie,
    field_actions: ' ',
    id: item.idTransaccion || idx,
    sitOrder: item.sitOrden,
    serie: item.serie,
    issuer: item.emisora
  });
}

const getSitOrderEquitiy = (item: any) => {
  switch(`${item.sitOrden}`.trim().toLowerCase()) {
      case 'registrada':
      case 'asignada parcial':
      case 'aceptada en bolsa': 
        return '0';
      default: 
        return '';
  }
}
const dataEquitiesMap = (item: any, idx: number): any => ({
  field_date: item.fecha,
  field_time: item.hora,
  field_folio: item.folio,
  field_name: item.emisoraSerie,
  field_type: item.tipoMovimiento === '0' ? 'Compra' : 'Venta',
  field_titles: item.titulos,
  field_asign_titles: item.titulosAsignados,
  field_price: (item.precio),
  field_prom_price: (item.precioPromAsign),
  field_validity: item.vigencia,
  field_status: item.sitOrden,
  //field_serie: item.serie,
  field_actions: ' ',
  id: item.idTransaccion || idx,
  sitOrder: getSitOrderEquitiy(item),
  showCancel: getSitOrderEquitiy(item) === '0',
  field_amount: item.importe,
  issuer: item.emisoraSerie,
  serie: ' '
});

const getEquitiesOrdersService = async (payload: { alias: string; contractId: string; }): Promise<any[]> => {
  try {
    const response = await httpClient.post(`${urlBase}/orders/equities`, payload);
    if (Array.isArray(response.data?.ordenes)) {
      return (response.data.ordenes as any[]).map(dataEquitiesMap)
        .sort((item1: any, item2: any) => {
          const d1 = item1.field_date as string;
          const t1 = item1.field_time as string;
          const d2 = item2.field_date as string;
          const t2 = item2.field_time as string;
          if(d1 !== d2) {
            return d2.localeCompare(d1);
          } else {
            return t2.localeCompare(t1);
          }
        });
    }
  } catch (e: any) {
  }
  return [];

}

const getFundsOrdersService = async (payload: { alias: string; contractId: string; }) => {
  try {
    const response = await httpClient.post(`${urlBase}/orders/funds`, payload);
    if (Array.isArray(response.data?.fondos)) {
      return (response.data?.fondos as any[]).map(dataFundsMap)
      .sort((item1: any, item2: any) => {
        const d1 = item1.field_date as string;
        const t1 = item1.field_time as string;
        const d2 = item2.field_date as string;
        const t2 = item2.field_time as string;
        if(d1 !== d2) {
          return d2.localeCompare(d1);
        } else {
          return t2.localeCompare(t1);
        }
      });;
    }
  } catch (e: any) {
  }
  return [];

}

const getPositionsService = async (payload: { alias: string; contractId: string }) : Promise<any[]> => {
  try {
    const response = await httpClient.get(`${urlBase}/list-positions/${payload.alias}/${payload.contractId}`);
    if (Array.isArray(response.data?.posiciones)) {
      return response.data.posiciones.map((item: any, idx: number) => ({
        field_cost: decimalFmt(item.costo),
        field_market: item.mercado,
        field_titles: item.titulosPosicion,
        field_varPctgAcum: item.varPctgAcum,
        field_varPctgDia: item.varPctgDia,
        field_issuer: item.emisora,
        field_serie: item.serie,
        field_amount: decimalFmt(item.importePosicion),
        field_plus: item.plusvalia,
        field_minus: item.minusvalia,
        field_status: ' ',
        issuer: item.emisora,
        serie: item.serie,
        id: idx
      }));
    }
  } catch (e) {
  }
  return [];
}

export {
  getContractsService,
  getStockPortfolioService,
  getPortfolioDetailService,
  getPortfolioHistoryService,
  getTransactionsService,
  getUserService,
  getListFundsService,
  getInfoTermsService,
  updateTermsService,
  getBusinessDays,
  makeTransferService,
  getEquitiesOrdersService,
  getFundsOrdersService,
  getPositionsService,
}

import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const WrapperMessage = styled.div <any>`
    display: flex;
    align-items: flex-start;
    font-family: Raleway;
    ${FontSize[10]};
    line-height: 1.45;
    color: white;
    background-color: #0D3570;
    gap: 10px;
    border-radius: 10px;
    padding: 10px;
    svg{
        min-width: 18px !important;
        width: 18px !important;
        height: 18px !important;
    }
`;

export { WrapperMessage };
import React, { type FC } from 'react'
import {ContentTable} from "./style"
import PropTypes from "./interface"
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';

const ThemeTable: FC<PropTypes> = (props) => {
    const {children, fullHeight, contenWatchlist} = props
    const theme = useAppSelector(currentTheme);
    return(
        <ContentTable fullHeight={fullHeight} contenWatchlist={contenWatchlist} theme={theme}>
            {children}
        </ContentTable>
    )

}

export default ThemeTable;


export default interface TransferRequestModel {
  idPersonaRegistra: string | number;
  fOperacion: string;
  idContrato: string | number;
  impMovimiento: string | number;
  cveDivisa: string;
  vostroNumCtaCliente: string;
  vostroNumCtaClabe: string;
}

export interface TransferResponseModel {
  firmaDigital?: string;
  numeroSerie?: string;
  idOrdenPago?: number;
  codigo?: number;
  mensaje?: number;
  detalle?: string;
  code?: string;
  message: string;
}

export const defaultTransferState : TransferRequestModel = {
  idPersonaRegistra: '',
  fOperacion: '',
  idContrato: '',
  impMovimiento: '',
  cveDivisa: '',
  vostroNumCtaCliente: '',
  vostroNumCtaClabe: '',
};
import { FinancialHeatMap, FinancialSimpleChart, FinancialTable, FinancialTicker, FinancialWinnersLosers } from 'fs-widgets-core';
import {
    AtomicCard,
    GridLayout
} from "../../atom"
import {ThemeTicker, ThemeTable, ThemeChart} from '../../widgets'
import { getBaseURL } from '../../utils';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useAppSelector } from '../../app/hooks';
import { currentLang } from '../../lang/langSlice';


export function Dashboard() {
    const lang = useAppSelector(currentLang);
    // const layout = [
    //     { i: "a", x: 0, y: 0, w: 1, h: 2, static: true },
    //     { i: "b", x: 1, y: 0, w: 3, h: 2, minW: 6, maxW: 6 },
    //     { i: "c", x: 8, y: 0, w: 1, h: 2 }
    // ];

    return (
        <GridLayout
            className="layout"
            breakpoints={{ lg: 1300, md: 1000, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 8, sm: 6, xs: 4, xxs: 4 }}
            rowHeight={23}
            >


            <div
                key="1"
                data-grid={{ w: 4, h: 14, x: 0, y: 0, isDraggable: false }}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Índices`}>
                    <ThemeTable>
                        <FinancialTable
                            headerFixed={true}
                            key={`main-FinancialTable-${12}`}
                            instance={'14'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>

            <div
                key="2"
                data-grid={{ w: 4, h: 14, x: 4, y: 0, isDraggable: false}}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Acciones`}>
                    <ThemeTable>
                        <FinancialTable
                            headerFixed={true}
                            key={`main-FinancialTable-${12}`}
                            instance={'13'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>

            <div
                key="3"
                data-grid={{ w: 4, h: 14, x: 8, y: 0, isDraggable: false }}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Ganadoras y Perdedoras`}>
                    <ThemeTable>
                        <FinancialWinnersLosers
                            key={`main-FinancialWinnersLosers-${5}`}
                            instance={'5'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>

            <div
                key="4"
                data-grid={{ w: 6, h: 11, x: 0, y: 14, isDraggable: false }}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Gráfica simple`}>
                        <ThemeChart>
                            <FinancialSimpleChart
                                key={`main-FinancialSimpleChart-${6}`}
                                instance={'6'}
                                language={lang.code}
                                singleChart={{}}
                            />
                        </ThemeChart>
                </AtomicCard>
            </div>

            <div
                key="5"
                data-grid={{ w: 6, h: 11, x: 6, y: 14, isDraggable: false }}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Heatmap SIC`}>
                    <FinancialHeatMap
                        key={`main-FinancialHeatMap-${18}`}
                        instance={'18'}
                        language={lang.code}
                    />
                </AtomicCard>
            </div>

            
            <div
                key="6"
                data-grid={{ w: 12, h: 2, x: 0, y: 25, isDraggable: false, static: false }}
            >   
                <ThemeTicker>
                    <AtomicCard
                        hoverable
                        configWidget
                        cardTitle={`Ticker`}
                    >
                        <FinancialTicker
                            key={`main-FinancialTicker-${10}`}
                            instance={'10'}
                            language={lang.code} 
                        />
                    </AtomicCard>
                </ThemeTicker>
            </div>



        </GridLayout>
    )
}
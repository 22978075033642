import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentNotice = styled.div`
    height: 100%;
    > div{
        background-color: transparent !important;
    }
    /* TABS */
    .wrapper-notice{
        background-color: transparent !important;
        >div[class*="MuiTabs"]{
            padding-right: 10px;
        }
        span[class*="MuiTabs-indicator"]{
            background-color: ${props => props.theme.palette.colors.secondary} !important;
        }
    }
    div[class*="MuiTabs"]{
        button{
            color: ${props => props.theme.palette.layout.text} !important;
            ${FontSize[12]};
        }
        &~.autoHeight{
            height: calc(100% - 30px) !important;
            .fullHeight{
                height: 100%;
            }
        }
    }
    .noticeCardDetail{
        background-color: ${props => props.theme.palette.layout.bg} !important;
        color: ${props => props.theme.palette.layout.text} !important;
        button[class*="colorSecondary"]{
            background-color: ${props => props.theme.palette.colors.primary} !important;
        }
        .contentDate{
            background-color: transparent;
        }
        .date{
            ${FontSize[10]};
            font-family: Roboto;
        }
        .title{
            ${FontSize[11]};
        }
        .description{
            ${FontSize[10]};
            line-height: 1.6;
        }
    }
    .autoHeight{
        height: 100%;
        .fullHeight{
            height: 100%;
        }
    }
    .contentTab{
        padding: 7px 10px 0 0 !important;
        .message{
            ${FontSize[11]};
            color:  ${props => props.theme.palette.layout.text} !important;
        }
        .contentSticky{
            background-color: ${props => props.theme.palette.layout.bg} !important;
            /* search special */
            div[class*="control"]{
                ${FontSize[11]};
                >div[class*="ValueContainer"], >div:first-child{
                    div{
                        color:  ${props => props.theme.palette.layout.text} !important;
                    }
                    div[class*="placeholder"]{
                        ${FontSize[11]};
                        color:  ${props => props.theme.palette.layout.text} !important;
                    }
                    div[class*="Input"]{
                        color:  ${props => props.theme.palette.layout.text} !important;
                    }
                }
                >div[class*="IndicatorsContainer"], >div:last-child{
                    div[class*="indicatorContainer"]{
                        color:  ${props => props.theme.palette.layout.text} !important;
                    }
                }
            }
            ~ div[class*="MenuPortal"], div[class*="menu"]  {
                > div{
                    background-color: ${props => props.theme.palette.card.header} !important;
                }
            }
        }
        .noticeCard{
            color: ${props => props.theme.palette.layout.text};
            &:hover{
                background-color: ${props => props.theme.palette.card.hover};
            }
            .header{
                .name{
                    color: ${props => props.theme.palette.layout.text} !important;
                }
            }
            .date{
                ${FontSize[10]};
            }
            .title{
                ${FontSize[11]};
            }
            .description{
                ${FontSize[10]};
                a{
                    color: ${props => props.theme.palette.colors.secondary} !important;
                }
            }
        }
    }
`;

export { ContentNotice };
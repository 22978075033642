import { type FC } from 'react'
import {Responsive, WidthProvider} from "react-grid-layout";
import GridPropsInterface from './interface'
import './grid/style.css'

const ResponsiveGridLayout = WidthProvider(Responsive);

const AtomicMenu:FC<GridPropsInterface> = (props) => {

    const {
        children,
        className,
        cols,
        rowHeight,
        breakpoints
    } = props;

    return (
        <ResponsiveGridLayout
            className={className}
            breakpoints={breakpoints}
            cols={cols}
            rowHeight={rowHeight}
        >
            {children}
        </ResponsiveGridLayout>
    )
}

export default AtomicMenu;
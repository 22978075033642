import React, { type FC } from 'react'
import {ContentHelp} from "./style"
import PropTypes from "./interface"
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';
import 'video-react/dist/video-react.css';

const ThemeHelp: FC<PropTypes> = (props) => {
    const {children} = props
    const theme = useAppSelector(currentTheme);
    return(
        <ContentHelp theme={theme}>
            {children}
        </ContentHelp>
    )
}

export default ThemeHelp;


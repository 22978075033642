import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { AuthProvider } from '@asgardeo/auth-react';
import { default as config } from './config.json'
import { RealtimeProvider } from 'fs-widgets-core';
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <AuthProvider config={config}>
    <Provider store={store}>
      <RealtimeProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </RealtimeProvider>
    </Provider>
  </AuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentChart = styled.div`
    height: 100% !important;
    .headerChart {
        padding: 0 !important;
        color: ${props => props.theme.palette.layout.text} !important;
        .contentName{
            color: ${props => props.theme.palette.layout.text} !important;
            svg{
                color: ${props => props.theme.palette.layout.text};
            }
        }

        .contentData{
            color: ${props => props.theme.palette.layout.text} !important;
            .left{
                .contentPrices{
                    .var, .percent{
                        color: ${props => props.theme.palette.layout.text} !important;
                    }
                }
            }
            .right{
                /* search special */
                div[class*="control"]{
                    ${FontSize[11]};
                    >div[class*="ValueContainer"], >div:first-child{
                        div{
                            color:  ${props => props.theme.palette.layout.text} !important;
                        }
                        div[class*="placeholder"]{
                            ${FontSize[11]};
                            color:  ${props => props.theme.palette.layout.text} !important;
                        }
                        div[class*="Input"]{
                            color:  ${props => props.theme.palette.layout.text} !important;
                        }
                    }
                    >div[class*="IndicatorsContainer"], >div:last-child{
                        div[class*="indicatorContainer"]{
                            color: ${props => props.theme.palette.layout.text} !important;
                        }
                    }
                }
                ~ div[class*="MenuPortal"], div[class*="menu"]  {
                    > div{
                        background-color: ${props => props.theme.palette.card.header} !important;
                    }
                }
                /* search */
                div[class*="adornedEnd"]{
                    border: none !important;
                    padding-right: 0 !important;
                    > div[class*="positionEnd"] {
                        color:  ${props => props.theme.palette.layout.text} !important;
                    }
                    [class*="notchedOutline"]{
                        border-color: ${props => props.theme.palette.line.border} !important;
                    }
                    input{
                        ${FontSize[11]};
                    }
                }
                .btn-group{
                    button{
                        background-color: transparent;
                        border-color: ${props => props.theme.palette.line.border} !important;
                        color: ${props => props.theme.palette.layout.text} !important;
                        border-right: 0;
                        ${FontSize[12]};
                        font-family: Roboto;
                        svg{
                            color: ${props => props.theme.palette.layout.text} !important;
                            fill: ${props => props.theme.palette.layout.text} !important;
                        }
                        &:not(:first-of-type){
                            border-right-color: ${props => props.theme.palette.line.border} !important;
                        }
                        &:hover, &.active{
                            background:  ${props => props.theme.palette.card.hover} !important;
                        }
                    }
                }
            }
        }
    }
    
    >div{
        background-color: transparent !important;
        [color^=up]{
            .contentName svg{
                color: ${props => props.theme.palette.colors.up};
            }
            .contentData{
                .left{
                    .contentPrices{
                        .var, .percent{
                            color: ${props => props.theme.palette.colors.up} !important;
                        }
                    }
                }
            }
        }
        [color^=down]{
            .contentName svg{
                color: ${props => props.theme.palette.colors.down};
            }
            .contentData{
                .left{
                    .contentPrices{
                        .var, .percent{
                            color: ${props => props.theme.palette.colors.down} !important;
                        }
                    }
                }
            }
        }
        g{
            &.cursor-line-stroke {
                stroke: ${props => props.theme.palette.layout.text} 
            }
            &.column-role {
                fill: ${props => props.theme.palette.chart.bg} 
            }
            &.tooltip-role {
                fill: ${props => props.theme.palette.chart.bg};
                g{
                    fill: ${props => props.theme.palette.chart.bg}
                }
            }
            &.tooltip-bot-role, .tooltip-right-role  {
                g{
                    fill: ${props => props.theme.palette.chart.bg};
                }
            }
            &[role="slider"] g {
                fill: ${props => props.theme.palette.chart.slider} 
            }

            &[role="button"] g {
                fill: ${props => props.theme.palette.chart.bgButton} 
            }
        }
        text {
            fill: ${props => props.theme.palette.chart.text};
        }
    }
    
    .tickerName{
        ${FontSize[14]};
    }
    .tickerNameLarge{
        ${FontSize[11]};
    }
    .left{
        .price{
            ${FontSize[22]}
        }
        .var{
            ${FontSize[12]}
        }
        .percent{
            ${FontSize[12]}
        }
        .date{
            ${FontSize[11]}
        }
        &.flex{
            align-items: center !important;
            .price{
                ${FontSize[14]}
            }
            .var{
                ${FontSize[14]}
            }
            .percent{
                ${FontSize[14]}
            }
        }
    }
`;

export { ContentChart };
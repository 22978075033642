import styled from "styled-components";
import Button from '@mui/material/Button';
import { FontSize } from '../../utils/Constants/FontSize';

const Btn = styled(Button) <any>`
  border-radius: 30px !important;
  box-shadow: none !important;
  color: black !important;
  border: none !important;
  ${FontSize[10]};
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: uppercase;
  letter-spacing: 0.7px !important;
  svg{
    width: 14px;
    height: 14px;
  }

  ${props => props.size === 'small' && 'height: 20px !important;'}
  ${props => props.size === 'medium' && 'height: 28px !important;'}
  ${props => props.size === 'large' && `
  height: 33px !important; 
  ${FontSize[11]};`}
  
  ${props => props.disabled && 'opacity: .6 !important;'}
  ${props => props.fullWidth && 'width: 100% !important;'}
  ${props => props.mobilefullwidth && '@media screen and (max-width:599px){width: 100% !important;}'}
  
  ${props => props.color === 'primary' && 'background-color: #002E9D !important; color: white !important;'}
  ${props => props.color === 'secondary' && 'background-color: #0098DD !important; color: white !important;'}
  ${props => props.color === 'success' && 'background-color: #1BC47D !important; color: white !important;'}
  ${props => props.color === 'error' && 'background-color: #EB5757 !important; color: white !important;'}
  ${props => props.color === 'warning' && 'background-color: #F2994A !important; color: black !important;'}
  ${props => props.color === 'default' && 'background-color: #8B8B8B !important; color: white !important;'}
  
  ${props => props.my && 'margin-top: .3rem !important; margin-bottom: .3rem !important;'}
  ${props => props.mx && 'margin-left: .5rem !important; margin-right: .5rem !important;'}
  ${props => props.ml && 'margin-left: .5rem !important;'}
  ${props => props.mr && 'margin-right: .5rem !important;'}
  ${props => props.mt && 'margin-top: .5rem !important;'}
  ${props => props.mb && 'margin-bottom: .5rem !important;'}
  
  &.queryIcon{
    transition: .3s;
    @media screen and (max-width:699px){
      padding: 0 !important;
      height: 28px !important;
      min-width: 28px !important;
      width: 28px !important;
      span{
        margin: 0 !important;
      }
      span.text{
        display: none;
      }
    }
  }
`;

export { Btn };
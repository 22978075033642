import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FinancialTransactionHistory } from 'fs-widgets-core';
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';
import { selectCurrentContract, selectCurrentUser } from '../../trader/traderSlice';
import { getTransactionsService } from '../../trader/traderAPI';
import { ThemeProvider } from 'styled-components';
import { createTheme } from '@mui/material';

const ThemeTransactionHistory: React.FC<any> = ({
    instance, children
}) => {
    const theme = useAppSelector(currentTheme);
    return (
        <ThemeProvider
            theme={theme}
        >
            {children}
        </ThemeProvider>
    );
}

export default ThemeTransactionHistory;
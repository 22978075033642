import styled from "styled-components";
import AsyncSelect from "react-select/async";
import { FontSize } from '../../utils/Constants/FontSize';

const AsyncSelectStyle = styled(AsyncSelect)`
  div[class*="option"] {
    color: #F00;
    ${FontSize[11]};
  }
    transition: 0.3s;
    > div[class*="control"] {
      border: 1px solid;
      border-radius: 6px !important;
      border-color: #0D3570 !important;
      box-shadow: none !important;
      background: transparent;
      box-sizing: border-box;
      color: white !important;
      ${FontSize[11]};
      min-height: 28px !important;
      min-width: 150px;
      width: auto !important;
      &:hover {
        .select__dropdown-indicator {
          color: white;
        }

        div[class*="placeholder"] {
          color: rgba(255,255,255,0.75);
        }
      }

      &[class*="focused"] {

        .select__dropdown-indicator,
        [class*="indicatorContainer"] {
          color: #FFFFFF;
        }

        div[class*="placeholder"] {
          color: rgba(255,255,255,0.75);
        }
      }
      div[class*="ValueContainer"] {
        div[class*="multiValue"], div[class*="singleValue"] {
          background-color: #0098DD;
          > div:first-child{
            color: #FFFFFF;
          }
          > div:last-child:hover{
            background-color: #0098DD;
            color: #FFFFFF;
            opacity: .5;
          }
          .itemSelect{
            .left{
              >div.name{
                display: none;
              }
            }
            .right{
              display: none;
            }
          }
        } 
        div[class*="singleValue"]{
          background-color: transparent !important;
        }
        div[class*="Input"] {
          color: #FFFFFF;
        } 
      }
      div[class*="IndicatorsContainer"] {
        div[class*="indicatorContainer"] {
          padding: 0 8px !important;
          svg{
            width: 16px !important;
            height: 16px !important;
          }
        }
      }
    }
    .select__indicator-separator {
      display: none !important;
    }
    .select__dropdown-indicator {
      color: #888682;
    }

    div[class*="placeholder"] {
      color: #888682;
      font-family: Raleway;
    }
    div[class*="multiValue"] {
      max-width: 70px;
    }
    > div[class*="MenuPortal"] {
      z-index: 10;
      > div{
        background-color: #001865 !important;
        >div >div{
          &:hover, &:focus{
            background-color: rgba(0,0,0,0.15) !important;
          }
      }
    }
  }
`;

const ItemSelect = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Raleway;
  .left{
    color: #FFFFFF !important;
    div.name{
      color: #CCCCCC !important;
      ${FontSize[9]};
      margin-top: 5px !important;
    }
  }
  .right{
    color: #CCCCCC !important;
    ${FontSize[9]};
  }
`;

export { AsyncSelectStyle, ItemSelect };
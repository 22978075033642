import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentDrawer = styled.div`
    > div{
        background-color: transparent !important;
    }
    [class*="MuiDrawer-paper"]{
        background-color: ${props => props.theme.palette.layout.bg} !important;
        color: ${props => props.theme.palette.layout.text} !important;
        padding: 70px 10px 10px !important;
        z-index: 19;
        box-sizing: border-box;
        font-family: Raleway !important;
    }
    .header{
        button{
            svg{
                color: ${props => props.theme.palette.layout.text} !important;
            }
        }
    }
    .body{
        /* search special */
        div[class*="control"]{
            ${FontSize[11]};
            >div[class*="ValueContainer"], >div:first-child{
                div{
                    color:  ${props => props.theme.palette.layout.text} !important;
                }
                div[class*="placeholder"]{
                    color:  ${props => props.theme.palette.layout.text} !important;
                    ${FontSize[11]};
                }
                div[class*="Input"]{
                    color:  ${props => props.theme.palette.layout.text} !important;
                }
            }
            >div[class*="IndicatorsContainer"], >div:last-child{
                div[class*="indicatorContainer"]{
                    color: ${props => props.theme.palette.layout.text} !important;
                }
            }
        }
        ~ div[class*="MenuPortal"], div[class*="menu"]  {
            > div{
                background-color: ${props => props.theme.palette.card.header} !important;
            }
        }
        .contentResult{
            max-height: calc(100vh - 205px) !important;
            .itemResult{
                button{
                    svg{
                        color: ${props => props.theme.palette.layout.text} !important;
                    }
                }
                &:hover{
                    background-color: ${props => props.theme.palette.card.hover} !important;
                }
            }
        }
    }
`;

export { ContentDrawer };
import styled from 'styled-components';

const Layout = styled.div <any>`
    box-sizing: border-box;
    width: 100%;
    height: 1px;
    min-height: 100%;
    transition: padding .1s ease-in-out;
    padding-top: 60px;
    padding-left: ${props => props.openMenu ? '70px' : 0} ;
    transition:all .2s;
    color: ${props => props.theme.palette.layout.text};
    background-color: ${props => props.theme.palette.layout.bg};
    overflow: hidden;
    .wrapper{
        box-sizing: border-box;
        margin-right: ${props => props.drawerOpen ? '260px' : '0'};
        padding-right: 10px;
        padding-left: ${props => props.openMenu ? 0 : '10px'} ;
        transition: margin 225ms ease-in-out;
        height: 100%;
        overflow-y: overlay;
        overflow-x: hidden;
    }
`;

export { Layout };

import React, { useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FinancialWatchlist } from 'fs-widgets-core';
import { ThemeTable } from '../../widgets'
import { useAppSelector } from '../../app/hooks';
import { currentLang } from '../../lang/langSlice';
import { CellCtrl } from 'ag-grid-community';
import { createTheme } from '@mui/material';

const WatchlistPage = () => {
    const lang = useAppSelector(currentLang);

    useEffect(() => {
        console.log('Init Page watchlist', CellCtrl)
    }, [])

    return (
        <ThemeTable contenWatchlist>
            <FinancialWatchlist
                headerFixed={true}
                instance='8'
                language={lang.code}
                key={'watchlist-page-8'}
                theme={createTheme({
                    palette: {
                        background: {
                            paper: '#001865',
                        }
                    },
                })}
                customNumberMatcher
            />
        </ThemeTable>
    )

}

export default WatchlistPage;

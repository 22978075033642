import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { defaultValue, ILanguage } from './interface/language.model';
import { fetchLangs } from './langAPI';

export interface LangState {
    current: ILanguage;
    languages: Array<ILanguage>;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: LangState = {
    current: defaultValue,
    languages: [defaultValue],
    status: 'idle',
}

export const getLangs = createAsyncThunk(
    'lang/fetchLangs',
    async () => {
        return await fetchLangs();
    }
);

export const langSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<ILanguage>) => {
            state.current = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLangs.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getLangs.fulfilled, (state, action) => {
                state.status = 'idle';
                state.languages = action.payload;
            })
            .addCase(getLangs.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setLanguage } = langSlice.actions;

export const currentLang = (state: RootState) => state.lang.current;
export const availableLangs = (state: RootState) => state.lang.languages;


export default langSlice.reducer;
export default interface FundListItem {
    bOpenPurchase: string;
    bPurchaseAmount: string;
    bPurchaseTitles: string;
    bExemptPlus: string;
    bReclassifiable: string;
    bSaleAmount: string;
    bSaleTitles: string;
    cveEntity: string;
    cveLegalEntity: string;
    issuer: string;
    purchaseLiquidDate: string;
    saleLiquidDate: string;
    pctComissionPurchaseMax: number;
    pctComissionPurchaseMin: number;
    pctNetYield: number;
    price: number;
    yield12m: number;
    yieldYear: number;
    yieldDaily: number;
    yieldMonthly: number;
    serie: string;
    type: string;
    titlesAvailable: number;
}

export interface FundListItemResponse {
    bAbiertoPublicoCompra: string;
    bCompraMonto: string;
    bCompraTitulos: string;
    bExentoPlus: string;
    bReclasificable: string;
    bVentaMonto: string;
    bVentaTitulos: string;
    cveEntidad: string;
    cvePerJuridica: string;
    emisora: string;
    fechaLiquidaCompra: string;
    fechaLiquidaVenta: string;
    pctComisionCompraMax: number;
    pctComisionCompraMin: number;
    pctRendimientoNeto: number;
    precio: number;
    rendimiento12meses: number;
    rendimientoAnual: number;
    rendimientoDiario: number;
    rendimientoMensual: number;
    serie: string;
    tipoValor: string;
    titulosDisponibles: number;
}

export function mapToFundListItem(item: FundListItemResponse): FundListItem {
    return {
        bOpenPurchase: item.bAbiertoPublicoCompra,
        bPurchaseAmount: item.bCompraMonto,
        bPurchaseTitles: item.bCompraTitulos,
        bExemptPlus: item.bExentoPlus,
        bReclassifiable: item.bReclasificable,
        bSaleAmount: item.bVentaMonto,
        bSaleTitles: item.bVentaTitulos,
        cveEntity: item.cveEntidad,
        cveLegalEntity: item.cvePerJuridica,
        issuer: item.emisora,
        purchaseLiquidDate: item.fechaLiquidaCompra,
        saleLiquidDate: item.fechaLiquidaVenta,
        pctComissionPurchaseMax: item.pctComisionCompraMax,
        pctComissionPurchaseMin: item.pctComisionCompraMin,
        pctNetYield: item.pctRendimientoNeto,
        price: item.precio,
        yield12m: item.rendimiento12meses,
        yieldYear: item.rendimientoAnual,
        yieldDaily: item.rendimientoDiario,
        yieldMonthly: item.rendimientoMensual,
        serie: item.serie,
        type: item.tipoValor,
        titlesAvailable: item.titulosDisponibles,
    }
};
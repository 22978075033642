import React, { type FC } from 'react'
import {ContentDrawer} from "./style"
import PropTypes from "./interface"
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';

const ThemeDrawer: FC<PropTypes> = (props) => {
    const {children} = props
    const theme = useAppSelector(currentTheme);
    return(
        <ContentDrawer theme={theme}>
            {children}
        </ContentDrawer>
    )
}

export default ThemeDrawer;


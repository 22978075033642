import { httpClient } from 'fs-http-core';
import { ILanguage } from './interface/language.model';

export function fetchLangs() {
    const url = `${process.env.REACT_APP_CONFIG_SERVICES}/api/languages`;
    return new Promise<Array<ILanguage>>((resolve) =>
        httpClient.get(url).then(response => {
            if(response.status === 200 && response.data) {
                resolve(response.data);
            }
        })
    );
}

const FSTheme = {
    ligth : {
        palette: {
            layout : {
                bg : '#FFFFFF',
                text : '#64748B',
            },
            header : {
                bg : '#001865',
                text : '#FFFFFF',
            },
            menu : {
                bg : '#FFFFFF',
                text : '#64748B',
                icon : '#002E9D',
                bgActive : '#001865',
                textActive : '#FFFFFF',
                iconActive : '#FFFFFF',
            },
            colors : {
                primary : '#002E9D',
                secondary : '#0098DD',
                error : '#EB5757',
                success : '#1BC47D',
                warning : '#F2994A',
                gray : '#7D7D7D',
                grayCard : '#64748B',
                up : '#1BC47D',
                up2 : '#179962',
                up3 : '#0D7D4E',
                down : '#EB5757',
                down2 : '#C73C3C',
                down3 : '#A12C2C',
                excel : '#0098DD',
            },
            line : {
                gray50 : 'rgba(100,100,100,.5)',
                border: '#F2F2F2'
            },
            card : {
                bg : 'transparent',
                text : '#64748B',
                header : '#001865',
                headerText : '#FFFFFF',
                hover : 'rgba(0, 0, 0, 0.05)',
                bgSellBuy : 'rgba(0, 46, 157, 0.8)',
            },
            table : {
                row : '#FAFAFA',
                text : '#64748B',
                headerText : '#06152C',
            },
            chart: {
                slider: '#3559b2',
                text: '#64748B',
                bg: '#f1f1f1',
                bgButton: '#bdbdbd',
            },
            accordion: {
                headerBg: '#002E9D',
                headerText: 'rgba(255,255,255,0.8)',
                headerInnerBg: '#ececec',
                headerInnerText: '#64748B',
                contentText: '#64748B',
            },
            configChart: {
                bgColorPicker: '#FFFFFF',
                colorPicker: '#64748B',
                selectBg: '#0098DD',
                selectColor: '#FFFFFF',
                inputCounter: '#ececec',
                borderInputCounter: '#ececec',
            },
            dialog : {
                bg : '#001865',
                text : 'rgba(255,255,255,0.8)',
                tableRow: '#072146',
                tableText: 'rgba(255,255,255,0.8)',
                tableHeaderText: 'rgba(255,255,255,0.5)',
                tableBorderInput: '#0D3570',
            },
            scroll : {
                bg : '#002e9d',
                track: 'rgba(0,0,0,0.15)',
            },
        },
        key: 'ligth'
    },
    dark : {
        palette: {
            layout : {
                bg : '#06152C',
                text : 'rgba(255,255,255,0.8)',
            },
            header : {
                bg : '#06152C',
                text : 'rgba(255,255,255,0.8)',
            },
            menu : {
                bg : '#06152C',
                text : 'rgba(255,255,255,0.8)',
                icon : 'rgba(255,255,255,0.8)',
                bgActive : '#0098DD',
                textActive : 'rgba(255,255,255,0.8)',
                iconActive : 'rgba(255,255,255,0.8)',
            },
            colors : {
                primary : '#002E9D',
                secondary : '#0098DD',
                error : '#EB5757',
                success : '#1BC47D',
                warning : '#F2994A',
                gray : '#7D7D7D',
                grayCard : '#64748B',
                up : '#1BC47D',
                up2 : '#179962',
                up3 : '#0D7D4E',
                down : '#EB5757',
                down2 : '#C73C3C',
                down3 : '#A12C2C',
                excel : '#FFFFFF',
            },
            line : {
                gray50 : 'rgba(100,100,100,.5)',
                border: '#0D3570'
            },
            card : {
                bg : 'transparent',
                text : 'rgba(255,255,255,0.8)',
                header : '#001865',
                headerText : 'rgba(255,255,255,0.8)',
                hover : 'rgba(0, 0, 0, 0.25)',
                bgSellBuy : '#072146',
            },
            table : {
                row : '#072146',
                text : 'rgba(255,255,255,0.8)',
                headerText : 'rgba(255,255,255,0.5)',
            },
            chart: {
                slider: '#002e9d',
                text: 'rgba(255,255,255,0.8)',
                bg: '#072146',
                bgButton: '#002d9e',
            },
            accordion: {
                headerBg: '#072146',
                headerText: 'rgba(255,255,255,0.8)',
                headerInnerBg: '#0D3570',
                headerInnerText: 'rgba(255,255,255,0.8)',
                contentText: 'rgba(255,255,255,0.8)',
            },
            configChart: {
                bgColorPicker: '#0D3570',
                colorPicker: 'rgba(255,255,255,0.8)',
                selectBg: '#0098DD',
                selectColor: 'rgba(255,255,255,0.8)',
                inputCounter: 'transparent',
                borderInputCounter: '#0098DD',
            },
            dialog : {
                bg : '#001865',
                text : 'rgba(255,255,255,0.8)',
                tableRow: '#072146',
                tableText: 'rgba(255,255,255,0.8)',
                tableHeaderText: 'rgba(255,255,255,0.5)',
                tableBorderInput: '#0D3570',
            },
            scroll : {
                bg : '#0098DD',
                track: '#213D65'
            },
        },
        key: 'dark'
    }
};
export default FSTheme;
import styled from "styled-components";
import Dialog  from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FontSize } from '../../utils/Constants/FontSize';

const DialogStyle = styled(Dialog) <any>`
  div[class*="backdrop"]{
    background-color: rgba(0,0,0,0.0) !important;
  }
  div[class*="paper"]{
      background-color: #001865!important;
      color: white;
  }
`;
const DialogActionsStyle = styled(DialogActions) <any>`
  padding: 0 24px 16px !important;
`;
const DialogContentStyle = styled(DialogContent) <any>`
  font-family: Raleway !important;
  color: white !important;
  ${FontSize[11]};
`;
const DialogContentTextStyle = styled(DialogContentText) <any>`
  font-family: Raleway !important;
  color: white !important;
  ${FontSize[11]};
`;
const DialogTitleStyle = styled(DialogTitle) <any>`
  font-family: Raleway !important;
  ${FontSize[12]};
  padding-right: 65px !important;
  padding-top: 8px !important;
  button{
    svg{
      color: white;
    }
  }
`;

export { DialogStyle, DialogActionsStyle, DialogContentStyle, DialogContentTextStyle, DialogTitleStyle };
import React from "react";
import { Test } from '../pages/test/test';
import { Home } from '../pages/home/home';
import { Home2 } from '../pages/home2/home';
import { Home3 } from '../pages/home3/home';
import { Dash } from '../pages/dash/dash';
import {Dashboard} from '../pages/dashboard/dashboard';
import { Help } from '../pages/help/help';
import TraderPage from '../pages/trader/TraderPage'
import WatchlistPage from '../pages/watchlist/watchlist';
import NotificationPage from '../pages/notification/notification';
import HistoryAlertPage from '../pages/notification/history';
import {TechnicalAnalysis} from '../pages/technical-analysis/technical-analysis'
import {ExcelAddIn} from '../pages/excelAddIn/excelAddIn'
import PortfolioPage from '../pages/portfolio/portfolio';
export const routes = [
    {
        path: '/test-monex-trader',
        component: <Test />
    },
    {
        path: '/home',
        component: <Home />
    },
    {
        path: '/home2',
        component: <Home2 />
    },
    {
        path: '/home3',
        component: <Home3 />
    },
    {
        path: '/dash',
        component: <Dash />
    },
    {
        path: '/dashboard',
        component: <Dashboard />
    },
    {
        path: '/watchlists',
        component: <WatchlistPage />
    },
    {
        path: '/help',
        component: <Help />
    },
    {
        path: '/portfolio',
        component: <PortfolioPage />
    },
    {
        path: '/oms',
        component: <TraderPage />
    },
    {
        path: '/notifications',
        component: <NotificationPage />
    },
    {
        path: '/notifications-history',
        component: <HistoryAlertPage />
    },
    {
        path: '/technical-analysis',
        component: <TechnicalAnalysis />
    },
    {
        path: '/add-in-xls',
        component: <ExcelAddIn />
    },
    {
        path : '*',
        component : <Home />
    }
]




import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FSTheme  from '../../theme/index';
import { RootState } from '../../app/store';
import {initialState} from './theme.init'

 
export const themeSlice = createSlice({
  name: 'theme',
  initialState,  
  reducers: {
    changeTheme: (state, action: PayloadAction<"dark" | "ligth">) => {
      state.mode = action.payload;
    },
  }
});

export const { changeTheme} = themeSlice.actions;
export const currentTheme = (state: RootState) => FSTheme[state.theme.mode];
 
export default themeSlice.reducer;



import React from 'react';
import { ThemeTechnicalAnalysis } from '../../widgets'

import { FinancialTechnicalAnalysis } from 'fs-widgets-core'

export const TechnicalAnalysis = () => {

    return (
        <ThemeTechnicalAnalysis>
            <FinancialTechnicalAnalysis
                instance='24'
                url=''
                language='es_MX'
            />
        </ThemeTechnicalAnalysis>
    )
}
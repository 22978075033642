import React, { useEffect, useState, type FC } from 'react';

import ThemeTable from '../themeTable'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import PropTypes from './interface';
import { selectFundsState, setSelectedFund } from '../../trader/traderSlice';
import { FinancialTableFunds } from 'fs-widgets-core';
import { currentTheme } from '../../theme/hooks/themeSlice';
const ThemeFunds: FC<any> = ({children}) => {
    const theme = useAppSelector(currentTheme);
    // const [data, setData] = useState<any[]>([]);
    // const dispatch = useAppDispatch();
    // const {
    //     listFunds,
    //     status,
    // } = useAppSelector(selectFundsState);
    // const handleOnClickRow = (payload: any) => {
    //     const { issuer, serie } = payload;
    //     if(issuer && serie) {
    //         dispatch(setSelectedFund({ issuer, serie } ));
    //     }
    // }
    // useEffect(() => {
    //     setData(listFunds.map((item, index) => ({
    //         issuer: item.issuer,
    //         serie: item.serie,
    //         yieldMonth: item.yieldMonthly,
    //         yield12m: item.yield12m,
    //         yieldYearly: item.yieldYear,
    //         type: item.type === '51' ? 'Renta fija' : item.type === '52' ? 'Renta variable' : '',
    //         titles: item.titlesAvailable,
    //         price: item.price,
    //         purchaseDate: item.purchaseLiquidDate,
    //         saleDate: item.saleLiquidDate,
    //         id: index
    //     })))
    // }, [listFunds]);
    return (
        <ThemeTable>
            {children}
            {/* <FinancialTableFunds
                instance={instance}
                language={language}
                theme={theme}
                data={data}
                status={status}
                handleOnClickRow={handleOnClickRow}
            /> */}
        </ThemeTable>
    )

}

export default ThemeFunds;
import { type FC } from 'react'
import {ItemMenu} from "./style"
import MenuItemPropsInterface from './interface';

const AtomicMenuItem:FC<MenuItemPropsInterface> = (props) => {

    const {
        children,
        onClick,
        value,
        key,
        disabled
    } = props;

    return (
        <ItemMenu
            onClick={onClick}
            value={value}
            key={key}
            disabled={disabled}
        >
            {children}
        </ItemMenu>
    )
}

export default AtomicMenuItem;
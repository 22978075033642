import styled, { css } from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';
import Typography from '@mui/material/Typography';

const Text = styled(Typography) <any>`
  /* ALIGN */
  ${props => props.textcenter && css`
    text-align: center !important;
  `}
  ${props => props.textright && css`
    text-align: right !important;
  `}
  
  ${props => props.size && `${FontSize[props.size]}`};
  
  /* FONT FAMILY */
  ${props => props.fontFamily === "primary" && css`
  font-family: Raleway !important;
  `}
  ${props => props.fontFamily === "secondary" && css`
      font-family: Roboto !important;
  `}

  /* COLOR */
  ${props => props.color === "secondary" && css`
      color: blue !important;
  `}
  ${props => props.color === "success" && css`
      color: green !important;
  `}
  ${props => props.color === "error" && css`
      color: red !important;
  `}
  ${props => props.color === "warning" && css`
      color: orange !important;
  `}
  ${props => props.color === "white" && css`
      color: white !important;
  `}

  /* EXTRAS */
  ${props => props.noMargin && css`
    margin: 0 !important;
  `}

  ${props => props.textnowrap && css`
    white-space: nowrap !important;
  `}
  ${props => props.bold && 'font-weight: 800 !important;'}
`;

export { Text };
import { type FC } from 'react'
import {MenuList} from "./style"
import MenuPropsInterface from './interface';

const AtomicMenu:FC<MenuPropsInterface> = (props) => {

    const {
        id,
        children,
        anchorEl,
        open,
        onClose,
    } = props;

    return (
        <MenuList 
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            {children}
        </MenuList>
    )
}

export default AtomicMenu;
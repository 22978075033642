import React from 'react';
import { FinancialHelp } from 'fs-widgets-core';
import { ThemeHelp } from '../../widgets'
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';
import WatchlistDark from "../../assets/images/watchlist-dark.png"
import VideoWatchlistDark from "../../assets/video/watchlist_1.mp4"
import AddInstrumentsDark from "../../assets/images/add-instruments-dark.png"
import VideoAddInstrumentsDark from "../../assets/video/add-instruments.mp4"
import NewWatchlistDark from "../../assets/images/new-watchlist-dark.png"
import VideoNewWatchlistDark from "../../assets/video/new-watchlist.mp4"
import ColumnsDark from "../../assets/images/columns-dark.png"
import VideoColumnsDark from "../../assets/video/columns.mp4"
import ContentDashDark from "../../assets/images/content-dash-dark.png"
import VideoContentDashDark from "../../assets/video/contenido.mp4"
import ContractDark from "../../assets/images/contract-dark.png"
import VideoContractDark from "../../assets/video/contrato.mp4"
import SearchDark from "../../assets/images/buscador-dark.png"
import VideoSearchDark from "../../assets/video/buscador.mp4"
import DashNewDark from "../../assets/images/dash-new-dark.png"
import VideoDashNewDark from "../../assets/video/dash-new.mp4"
import PortfolioDark from "../../assets/images/portfolio-dark.png"
import VideoPortfolioDark from "../../assets/video/portafolio.mp4"
import ThemeDark from "../../assets/images/teme-dark.png"
import VideoThemeDark from "../../assets/video/tema.mp4"
import ExcelDark from "../../assets/images/add-in-xls-dark.png"
import VideoExcelDark from "../../assets/video/excel-add-in.mp4"
import Manual from "../../assets/pdf/Monex_trader_manual_usuario.pdf"

const data = [
    {
        label: 'Watchlist',
        sourceBg: WatchlistDark,
        sourceVideo: VideoWatchlistDark,
    },
    {
        label: 'Agregar instrumentos en watchlist',
        sourceBg: AddInstrumentsDark,
        sourceVideo: VideoAddInstrumentsDark,
    },
    {
        label: 'Nuevo watchlist',
        sourceBg: NewWatchlistDark,
        sourceVideo: VideoNewWatchlistDark,
    },
    {
        label: 'Columnas de watchlist',
        sourceBg: ColumnsDark,
        sourceVideo: VideoColumnsDark,
    },
    {
        label: 'Contenido dashboard',
        sourceBg: ContentDashDark,
        sourceVideo: VideoContentDashDark,
    },
    {
        label: 'Contratos',
        sourceBg: ContractDark,
        sourceVideo: VideoContractDark,
    },
    {
        label: 'Buscador general',
        sourceBg: SearchDark,
        sourceVideo: VideoSearchDark,
    },
    {
        label: 'Crear nuevo dashboard',
        sourceBg: DashNewDark,
        sourceVideo: VideoDashNewDark,
    },
    {
        label: 'Portafolio',
        sourceBg: PortfolioDark,
        sourceVideo: VideoPortfolioDark,
    },
    {
        label: 'Cambio de tema',
        sourceBg: ThemeDark,
        sourceVideo: VideoThemeDark,
    },
    {
        label: 'Excel Add In',
        sourceBg: ExcelDark,
        sourceVideo: VideoExcelDark,
    },
]
const dataDark = [
    {
        label: 'Watchlist',
        sourceBg: WatchlistDark,
        sourceVideo: VideoWatchlistDark,
    },
    {
        label: 'Agregar instrumentos en watchlist',
        sourceBg: AddInstrumentsDark,
        sourceVideo: VideoAddInstrumentsDark,
    },
    {
        label: 'Nuevo watchlist',
        sourceBg: NewWatchlistDark,
        sourceVideo: VideoNewWatchlistDark,
    },
    {
        label: 'Columnas de watchlist',
        sourceBg: ColumnsDark,
        sourceVideo: VideoColumnsDark,
    },
    {
        label: 'Contenido dashboard',
        sourceBg: ContentDashDark,
        sourceVideo: VideoContentDashDark,
    },
    {
        label: 'Contratos',
        sourceBg: ContractDark,
        sourceVideo: VideoContractDark,
    },
    {
        label: 'Buscador general',
        sourceBg: SearchDark,
        sourceVideo: VideoSearchDark,
    },
    {
        label: 'Crear nuevo dashboard',
        sourceBg: DashNewDark,
        sourceVideo: VideoDashNewDark,
    },
    {
        label: 'Portafolio',
        sourceBg: PortfolioDark,
        sourceVideo: VideoPortfolioDark,
    },
    {
        label: 'Cambio de tema',
        sourceBg: ThemeDark,
        sourceVideo: VideoThemeDark,
    },
    {
        label: 'Excel Add In',
        sourceBg: ExcelDark,
        sourceVideo: VideoExcelDark,
    },
]

export function Help() {
    const theme = useAppSelector(currentTheme);
    return (
        <ThemeHelp>
            <FinancialHelp
                data={(theme.key === 'dark') ? dataDark : data}
                titleHero="¿Cómo puedo ayudarte hoy?"
                descriptionHero="En esta sección podrás encontrar tutoriales que te ayuden a resolver tus dudas y/o preguntas."
                urlManual={Manual}
            />
        </ThemeHelp>
    )
}


import React, { type FC } from 'react'
import {WrapperUser} from "./style"
import PropsInterface from "./interface"
import {
    AtomicText,
    AtomicTextFile,
    AtomicMenuItem
} from "../../../../atom"
import HeaderDrawer from '../headerDrawer';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectContracts, selectCurrentContract, setContract } from '../../../../trader/traderSlice';

const InfoUser: FC<PropsInterface> = (props) => {
    const{
        open,
        onClose,
        //@ts-ignore
        valuePortfolio,
        //@ts-ignore
        cash,
        name
    } = props
    const dispatch = useAppDispatch();
    const contracts = useAppSelector(selectContracts);
    const currentContract = useAppSelector(selectCurrentContract);
    const texts = [
        {
            bold: false,
            mt: 1,
            fontFamily: 'primary',
            label: 'Valor cartera:',
        },
        {
            bold: true,
            mt: 0,
            fontFamily: 'secondary',
            label: valuePortfolio,
        },
        {
            bold: false,
            mt: 1,
            fontFamily: 'primary',
            label: 'Efectivo disponible:',
        },
        {
            bold: true,
            mt: 0,
            fontFamily: 'secondary',
            label: cash,
        },
        {
            bold: false,
            mt: 1,
            fontFamily: 'primary',
            label: 'Inversionista:',
        },
        {
            bold: true,
            mt: 0,
            fontFamily: 'secondary',
            label: name,
        },
    ];

    const handleChangeAcount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newContract = contracts.find(c => c.id === e.target.value);
        if(newContract) {
            dispatch(setContract(newContract));
        }
    }
    return(
        <WrapperUser open={open}>
            <HeaderDrawer
                titleDrawer='Información de usuario'
                onClose={onClose}
            />
            
            <AtomicTextFile
                id="contract"
                label="Contrato"
                select
                value={currentContract?.id || ''}
                onChange={handleChangeAcount}
                fullWidth
                required
            >
                {contracts.map((c:any) => (
                    <AtomicMenuItem 
                        key={`Contract-${c.id}`} 
                        value={c.id}
                    >
                            {c.id}
                    </AtomicMenuItem>  
                    
                ))}
            </AtomicTextFile>
            {texts.map((item:any, index) => (
                <AtomicText size="11" bold={item.bold} fontFamily={item.fontFamily} mt={item.mt}>{item.label}</AtomicText>
            ))}
        </WrapperUser>
    )
}

export default InfoUser;


import { useState, type FC } from 'react';
import { useAppSelector } from "../../app/hooks";
import { currentTheme } from "../../theme/hooks/themeSlice";
import { useElements } from "./data";
import { Menu } from "./style";

import { selectShowMenu } from '../../features/settings/settingsSlice';
import { PropsMenuLeft } from './interface';

const MenuLeft: FC<PropsMenuLeft> = (props) => {
  
  const openMenu = useAppSelector(selectShowMenu);
  const theme = useAppSelector(currentTheme);
  const items = useElements();

  const [selected, setSelected] = useState<string | null>('');

  return (
    <Menu theme={theme} openMenu={openMenu}>
      <ul>
        {(items ?? []).map((item : any, i: number) => (
          <>
            <li key={i}>
              <button className={(selected === '' && i === 0) ? 'active' : selected === item.name ? 'active' : ''} onClick={() => {
                setSelected(item.name);
                item?.onClick();
              }}>
                {item.icon} <span>{item.name}</span>
              </button>
            </li>
          </>
        ))}
      </ul>
    </Menu>
  );
};

export default MenuLeft;

import React from 'react';
import { FinancialHeatMap, FinancialMonitor, FinancialNotice, FinancialSimpleChart, FinancialTable, FinancialTicker, FinancialTwitter, FinancialWinnersLosers, useRealtime} from 'fs-widgets-core';
import {
    AtomicCard,
    GridLayout
} from "../../atom"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {ThemeTicker, ThemeTable, ThemeChart, ThemeNotice, ThemeHeatMap, ThemeTwitter} from '../../widgets'
import { useAppSelector } from '../../app/hooks';
import { currentLang } from '../../lang/langSlice';


export function Test() {
    const lang = useAppSelector(currentLang);
    const {initSettings, settings} = useRealtime();
    return (
        <GridLayout
            className="layout"
            breakpoints={{ lg: 1300, md: 1000, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 12, sm: 6, xs: 6, xxs: 3 }}
            rowHeight={51}>

            <div
                key="1"
                data-grid={{ w: 12, h: 2, x: 0, y: 0, isDraggable: false, static: true }}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Ticker Monedas`}
                    openDrawer={() => initSettings({ ...settings, open: true, instance: 10 })}
                    >
                    <ThemeTicker>
                        <FinancialTicker
                            key={`main-FinancialTicker-${10}`}
                            instance={'10'}
                            language={lang.code}  
                        />
                    </ThemeTicker>
                </AtomicCard>
            </div>

            <div
                key="2"
                data-grid={{ w: 12, h: 2, x: 0, y: 2, isDraggable: false, static: true }}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Ticker Renta Variable`}
                    openDrawer={() => initSettings({ ...settings, open: true, instance: 11 })}
                    >
                    <ThemeTicker>
                        <FinancialTicker
                            key={`main-FinancialTicker-${11}`}
                            instance={'11'}
                            language={lang.code} 
                        />
                    </ThemeTicker>
                </AtomicCard>
            </div>

            <div
                key="3"
                data-grid={{ w: 12, h: 2, x: 0, y:4, isDraggable: false, static: true }}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Ticker Multi asset`}
                    openDrawer={() => initSettings({ ...settings, open: true, instance: 12 })}
                    >
                    <ThemeTicker>
                        <FinancialTicker
                            key={`main-FinancialTicker-${12}`}
                            instance={'12'}
                            language={lang.code} 
                        />
                    </ThemeTicker>
                </AtomicCard>
            </div>

            <div
                key="4"
                data-grid={{ w: 6, h: 7, x: 0, y: 6, isDraggable: false}}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Tabla Acciones`}
                    openDrawer={() => initSettings({ ...settings, open: true, instance: 13 })}
                    >
                    <ThemeTable>
                        <FinancialTable
                            headerFixed={true}
                            key={`main-FinancialTable-${13}`}
                            instance={'13'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>

            <div
                key="6"
                data-grid={{ w: 6, h: 7, x: 6, y: 6, isDraggable: false }}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Tabla índices`}
                    openDrawer={() => initSettings({ ...settings, open: true, instance: 14 })}
                    >
                    <ThemeTable>
                        <FinancialTable
                            headerFixed={true}
                            key={`main-FinancialTable-${14}`}
                            instance={'14'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>

            <div
                key="7"
                data-grid={{ w: 6, h: 8, x: 0, y: 13, isDraggable: false }}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Tabla Monedas`}
                    openDrawer={() => initSettings({ ...settings, open: true, instance: 15 })}
                    >
                    <ThemeTable>
                        <FinancialTable
                            headerFixed={true}
                            key={`main-FinancialTable-${15}`}
                            instance={'15'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>

            <div
                key="8"
                data-grid={{ w: 6, h: 8, x: 6, y: 13, isDraggable: false }}
            >
                <AtomicCard
                    configWidget
                    cardTitle={`Tabla Commodities`}
                    openDrawer={() => initSettings({ ...settings, open: true, instance: 17 })}
                >
                    <ThemeTable>
                        <FinancialTable
                            headerFixed={true}
                            key={`main-FinancialTable-${17}`}
                            instance={'17'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>
            <div
                key="9"
                data-grid={{ w: 6, h: 8, x: 0, y: 21, minW: 4, minH: 1, isDraggable: false, static: true }}
            >
                <AtomicCard
                    cardTitle={`Heatmap FX`}>
                        <ThemeHeatMap>
                            <FinancialHeatMap
                                key={`main-FinancialHeatMap-${19}`}
                                instance={'19'}
                                language={lang.code}
                            />
                        </ThemeHeatMap>
                </AtomicCard>
            </div>

            <div
                key="10"
                data-grid={{ w: 6, h: 8, x: 6, y: 21, minW: 4, minH: 1, isDraggable: false, static: true }}
            >
                <AtomicCard
                    cardTitle={`Heatmap índices`}>
                        <ThemeHeatMap>
                            <FinancialHeatMap
                                key={`main-FinancialHeatMap-${20}`}
                                instance={'20'}
                                language={lang.code}
                            />
                        </ThemeHeatMap>
                </AtomicCard>
            </div>

            <div
                key="11"
                data-grid={{ w: 6, h: 8, x: 0, y: 29, minW: 4, minH: 1, isDraggable: false, static: true }}
            >
                <AtomicCard
                    cardTitle={`Heatmap Local`}>
                        <ThemeHeatMap>
                            <FinancialHeatMap
                                key={`main-FinancialHeatMap-${22}`}
                                instance={'4'}
                                language={lang.code}
                            />
                        </ThemeHeatMap>
                </AtomicCard>
            </div>

            <div
                key="12"
                data-grid={{ w: 6, h: 8, x: 6, y: 29, isDraggable: false}}
            >
                <AtomicCard
                    cardTitle={`Heatmap SIC`}>
                        <ThemeHeatMap>
                            <FinancialHeatMap
                                key={`main-FinancialHeatMap-${18}`}
                                instance={'18'}
                                language={lang.code}
                            />
                        </ThemeHeatMap>
                </AtomicCard>
            </div>
            <div
                key="13"
                data-grid={{ w: 6, h: 8, x: 0, y: 37, isDraggable: false }}
            >
                <AtomicCard
                    cardTitle={`G&L BMV`}>
                    <ThemeTable>
                        <FinancialWinnersLosers
                            key={`main-FinancialWinnersLosers-${5}`}
                            instance={'5'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>
            <div
                key="14"
                data-grid={{ w: 6, h: 8, x: 6, y: 37, isDraggable: false }}
            >
                <AtomicCard
                    cardTitle={`G&L SIC`}>
                    <ThemeTable>
                        <FinancialWinnersLosers
                            key={`main-FinancialWinnersLosers-${21}`}
                            instance={'21'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>
            <div
                key="15"
                data-grid={{ w: 12, h: 8, x: 0, y: 45, isDraggable: false }}
            >
                <AtomicCard
                    cardTitle={`Gráfica simple`}>
                    <ThemeChart>
                        <FinancialSimpleChart
                            key={`main-FinancialSimpleChart-${6}`}
                            instance={'6'}
                            language={lang.code}
                            singleChart={{}}
                        />
                    </ThemeChart>
                </AtomicCard>
            </div>

            <div
                key="16"
                data-grid={{ w: 3, h: 12, x: 0, y: 53, minW: 3, minH: 1, isDraggable: false}}
            >
                <AtomicCard
                    cardTitle={`Monitor`}>
                    <ThemeTable fullHeight>
                        <FinancialMonitor
                            headerFixed={true}
                            key={`main-FinancialMonitor-${21}`}
                            instance={'3'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>
            <div
                key="17"
                data-grid={{ w: 3, h: 12, x: 3, y: 53, minW: 3, minH: 1, isDraggable: false }}
            >
                <AtomicCard
                    cardTitle={`Noticias`}
                    noScroll
                >
                    <ThemeNotice>
                        <FinancialNotice
                            key={`main-FinancialNotices-${7}`}
                            instance={'7'}
                            language={lang.code}
                        />
                    </ThemeNotice>
                </AtomicCard>
            </div>
            <div
                key="18"
                data-grid={{ w: 3, h: 12, x: 6, y:53, minW: 3, minH: 1, isDraggable: false}}
            >
                <AtomicCard
                    cardTitle={`Top News`}
                    noScroll
                >
                    <ThemeNotice>
                        <FinancialNotice
                            key={`main-FinancialNotices-${22}`}
                            instance={'22'}
                            language={lang.code}
                        />
                    </ThemeNotice>
                </AtomicCard>
            </div>
            <div
                key="19"
                data-grid={{ w: 3, h: 12, x: 9, y: 53, minW: 3, minH: 1, isDraggable: false}}
            >
                <AtomicCard
                    cardTitle={`Twitter`}
                >
                    <ThemeTwitter>
                        <FinancialTwitter
                            key={`main-FinancialTwitter-${23}`}
                            instance={'9'}
                            language={lang.code}
                        />
                    </ThemeTwitter>
                </AtomicCard>
            </div>

        </GridLayout>
    )
    
}
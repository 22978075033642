import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentHelp = styled.div`
    .hero{
        background-color: ${props => props.theme.palette.table.row};
        color: ${props => props.theme.palette.layout.text} !important;
        .hero-text-content-h1{
            ${FontSize[28]};
        }
        .hero-text-content{
            ${FontSize[14]};
        }
    }
    .content-results{
        color: ${props => props.theme.palette.layout.text} !important;
    }
    .cardHelp{
        .bg{
            svg{
                color: white;
            }
        }
        .label-card{
            ${FontSize[12]};
        }
    }
    /* search */
    div[class*="adornedEnd"]{
        padding-right: 0 !important;
        color:  ${props => props.theme.palette.layout.text} !important;
        input{
            ${FontSize[11]};
            color:  ${props => props.theme.palette.layout.text} !important;
            &::placeholder{
                color:  ${props => props.theme.palette.layout.text} !important;
            }
        }
        > div[class*="positionEnd"] {
            color:  ${props => props.theme.palette.layout.text} !important;
        }
        [class*="notchedOutline"]{
            border-color: ${props => props.theme.palette.line.border} !important;
        }
    }
    
`;

export { ContentHelp };
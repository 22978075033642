import { type FC } from 'react'
import {ButtonIcon} from './style';
import ButtonPropsProperties from './interface';

const AtomicButtonIcon: FC<ButtonPropsProperties> = (props) => {
    
    const {
        variant,
        color, 
        coloricon,
        size,
        disabled,
        onClick,
        my,
        mx,
        ml,
        mt,
        mr,
        mb,
        sx,
        className,
        children
    } = props

    return (
        <ButtonIcon 
            onClick={onClick}
            disabled={disabled}
            variant={variant}
            color={color}
            coloricon={coloricon}
            size={size ? size : 'medium'}
            className={className}
            my={my}
            mx={mx}
            ml={ml}
            mt={mt}
            mr={mr}
            mb={mb}
            sx={sx}
        >
            {children}
        </ButtonIcon>
    )
}

export default AtomicButtonIcon;
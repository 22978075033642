
//TODO: retrive from env variable.
export const getBaseURL = () => {
    return `financialso-monextradin-j33na2ad2qj.ws-us90.gitpod.io`;
}

export const dummyWidgets = [
    {
        component: 'FinancialTicker',
        instance: 10,
        language: 'es_MX'
    },
    {
        component: 'FinancialTicker',
        instance: 11,
        language: 'es_MX'
    },
    {
        component: 'FinancialTicker',
        instance: 12,
        language: 'es_MX'
    },
    {
        component: 'FinancialTable',
        instance: 13,
        language: 'es_MX'
    },
    {
        component: 'FinancialTable',
        instance: 14,
        language: 'es_MX'
    },
    {
        component: 'FinancialTable',
        instance: 15,
        language: 'es_MX'
    },
    {
        component: 'FinancialTable',
        instance: 16,
        language: 'es_MX'
    },
    {
        component: 'FinancialWinnersLosers',
        instance: 5,
        language : 'es_MX'
    },
    {
        component: 'FinancialWinnersLosers',
        instance: 18,
        language : 'es_MX'
    },
    {
        component: 'FinancialWinnersLosers',
        instance: 19,
        language : 'es_MX'
    },
    {
        component: 'FinancialWinnersLosers',
        instance: 20,
        language : 'es_MX'
    },
    {
        component: 'FinancialHeatMap',
        instance: 18,
        language : 'es_MX'
    },
    {
        component: 'FinancialHeatMap',
        instance: 19,
        language : 'es_MX'
    },
    {
        component: 'FinancialHeatMap',
        instance: 20,
        language : 'es_MX'
    },
    {
        component: 'FinancialSimpleChart',
        instance: 6,
        language : 'es_MX'
    }
]

export const decimalFmt = (value: any, forceDecimals=true) => {
    const n = Number(value);
    if(isNaN(n)) {
        return value;
    }
    const numberFormatter = Intl.NumberFormat('es-MX', {
        minimumFractionDigits: forceDecimals ? 2 : undefined,
        maximumFractionDigits: forceDecimals ? 2 : undefined,
    });

    return numberFormatter.format(n);
}

export const fmtInputValue = (value: string) => {
    if(!value) {
        return value;
    }
    const num = value.replace(/,/g, '');
    if (!/^[0-9.]*$/.test(num) || isNaN(Number(num))) {
        return null;
    } else {
        const [integer, decimal] = num.split('.');
        const decimalPoint = num.includes('.') ? '.' : '';
        const fractions = decimal ? decimal.substring(0 , Math.min(decimal.length, 2)) : '';
        return `${decimalFmt(integer, false)}${decimalPoint}${fractions}`
    }
}
import React, { type FC } from 'react'
import {ContentChart} from "./style"
import PropTypes from "./interface"
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';

const ThemeHeatMap: FC<PropTypes> = (props) => {
    const {children} = props
    const theme = useAppSelector(currentTheme);
    return(
        <ContentChart theme={theme}>
            {children}
        </ContentChart>
    )
}

export default ThemeHeatMap;


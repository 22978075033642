import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentDash = styled.div`
    margin-top: 10px;
    .wraper-dash{
        gap: 10px;
        .headerChart{
            padding: 10px !important;
            padding-bottom: 0 !important;
        }
    }
    .header-dash{
        gap: 5px;
        /* TABS */
        .tabs-dash{
            .tab-dash{
                button{
                    color: ${props => props.theme.palette.layout.text} !important;
                    ${FontSize[12]};
                }
                &:before {
                    content: ' ';
                    width: 2px;
                    height: 30px;
                    background: ${props => props.theme.palette.line.border} !important;
                }
                &:first-child:before { 
                    display: none;
                }
                &[class*="selected"], &.active{
                    &:before {
                        display: none;
                    }
                    button{
                        color: #FFFFFF !important;
                    }
                }
            }
            span[class*="MuiTabs-indicator"]{
                background-color: ${props => props.theme.palette.colors.primary} !important;
            }
        }
        .add_button{
            background-color: ${props => props.theme.palette.colors.primary} !important;
        }
        .group-btn-dash{
            padding: .3rem;
            background-color: ${props => props.theme.palette.card.header} !important;
                button{
                    background-color: ${props => props.theme.palette.colors.secondary} !important;
                }
        }
    }

    .add-widget{
        border-color: ${props => props.theme.palette.line.gray50} !important;
        .add-btn-widget{
            background-color: ${props => props.theme.palette.colors.primary} !important;
            border: none !important;
            ${FontSize[11]};
        }
    }

    .card-header-widget{
        background-color: ${props => props.theme.palette.card.header};
        color: ${props => props.theme.palette.card.headerText};
        span[class*="MuiCardHeader-title"]{
            ${FontSize[12]};
            font-weight: 700;
        }
        button{
            svg{
                color: ${props => props.theme.palette.card.headerText};
            }
        }
    }
`;

export { ContentDash };
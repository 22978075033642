import styled from 'styled-components';

const ContainerLoader = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.75) !important;
    height: 100% !important;
    width: 100% !important;
    top: 0;
    left: 0;
    z-index: 9;
`;

export { ContainerLoader };
/* width: ${props => props.width ? props.width : ""}; */
/* color: ${props => Colors[props.theme].primary.default} !important; */
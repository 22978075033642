import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentBolet = styled.div`
    > div{
        background-color: transparent !important;
        width: 100%;
        
        /* TABS */
        div[class*="MuiTabs"]{
            button{
                color: ${props => props.theme.palette.layout.text} !important;
            }
            span[class*="MuiTabs-indicator"]{
                background-color: ${props => props.theme.palette.colors.secondary} !important;
            }
        }
        .contentTab{
            
            label{
                color: ${props => props.theme.palette.layout.text} !important;
                &[class*="MuiFormControlLabel"]{
                    span{
                        /* &[class*="MuiRadio"]{
                            color: ${props => props.theme.palette.line.border} !important;
                        } */
                        &[class*="checked"]{
                            color: ${props => props.theme.palette.colors.secondary} !important;
                        }
                        &[class*="MuiTypography"]{
                            color: ${props => props.theme.palette.layout.text} !important;
                        }
                    }
                }
            }
            /* search special */
            div[class*="control"]{
                ${FontSize[11]};
                >div[class*="ValueContainer"], >div:first-child{
                    div{
                        color:  ${props => props.theme.palette.layout.text} !important;
                    }
                    div[class*="placeholder"]{
                        color:  ${props => props.theme.palette.layout.text} !important;
                        ${FontSize[11]};
                    }
                    div[class*="Input"]{
                        color:  ${props => props.theme.palette.layout.text} !important;
                    }
                }
                >div[class*="IndicatorsContainer"], >div:last-child{
                    div[class*="indicatorContainer"]{
                        color:  ${props => props.theme.palette.layout.text} !important;
                    }
                }
            }
            ~ div[class*="MenuPortal"], div[class*="menu"]  {
                > div{
                    background-color: ${props => props.theme.palette.card.header} !important;
                }
            }
            /* buy and Sell */
            .buyAndSell{
                background-color: ${props => props.theme.palette.card.bgSellBuy} !important;
                label{
                    color: #FFFFFF !important;
                }
                .buy{
                    background-color: ${props => props.theme.palette.colors.success} !important;
                    label{
                        color: #FFFFFF !important;
                    }
                }
                .sell{
                    background-color: ${props => props.theme.palette.colors.error} !important;
                    label{
                        color: #FFFFFF !important;
                    }
                }
            }
            /* inputs */
            div[class*="MuiTextField"]{
                label{
                    color: ${props => props.theme.palette.layout.text} !important;
                }
                [class*="disabled"]{
                    -webkit-text-fill-color: ${props => props.theme.palette.layout.text} !important;
                }
                [class*="positionStart"]{
                    p{
                        color: ${props => props.theme.palette.layout.text} !important;
                    }
                }
                .MuiSelect-select{
                    color:  ${props => props.theme.palette.layout.text} !important;
                }
                input{
                    color:  ${props => props.theme.palette.layout.text} !important;
                    &::placeholder{
                        color:  ${props => props.theme.palette.layout.text} !important;
                    }
                }
                [class*="MuiSelect-iconStandard"]{
                    color: ${props => props.theme.palette.layout.text} !important;
                }
            }
            .input-range{
                .customBtn{
                    color: ${props => props.theme.palette.layout.text} !important;
                    svg{
                        color: ${props => props.theme.palette.layout.text} !important;
                    }
                }
            }
            /* buttons */
            button[class*="Success"]{
                background-color: ${props => props.theme.palette.colors.success} !important;
            }
            button[class*="Error"]{
                background-color: ${props => props.theme.palette.colors.error} !important;
            }
        }
    }
`;

export { ContentBolet };
import styled from 'styled-components';


const HeaderStyle = styled.header`
    position: fixed;
    background-color: ${props => props.theme.palette.header.bg};
    height: 60px !important;
    width: 100% !important;
    max-width: 100% !important;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
    padding-right: 1rem;
    color: ${props => props.theme.palette.header.text};
    z-index: 1201;

    .header-color-svg svg{
        color: white;
    }

    .content-left{
        display: flex;
        align-items: center;
        flex: 1;
        .primary-content{
            display: flex;
            align-items: center;
            .logo{
                height: 26px;
                margin-left: 15px;
                margin-right: 60px;
                @media screen and (max-width:580px){
                    display: none;
                }
            }
        }
        .secondary-content{
            display: flex;
            align-items: flex-start;
            gap: 10px;
            flex: 1;
            justify-content: space-around;
            max-width: 620px;
            .info{
                max-width: 325px;
                p{
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            @media screen and (max-width:1120px){
                display: none;
            }
        }
    }
    .content-right{
        display: flex;
        padding-right: 1rem;
        gap: 9px;
        align-items: center;
        
        @media screen and (min-width:1121px){
            .show-user-btn{
                display: none;
            }
        }
    }
    .pointer{
        cursor: pointer;
    }
`;

export { HeaderStyle };
import { type FC } from 'react'
import { Layout } from "./style"
import AppLayoutPropsInterface from "./interface"
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';
import { selectShowMenu } from '../../features/settings/settingsSlice';

const AppLayout: FC<AppLayoutPropsInterface> = (props) => {
    const theme = useAppSelector(currentTheme);
    const openMenu = useAppSelector(selectShowMenu);
    const {
        drawerOpen,
    } = props
    return (
        <Layout theme={theme} drawerOpen={drawerOpen} openMenu={openMenu}>
            {props.children}
        </Layout>
    )
}

export default AppLayout;

import { FinancialMonitor, FinancialNotice, FinancialTwitter} from 'fs-widgets-core';
import {
    AtomicCard,
    GridLayout
} from "../../atom"
import { getBaseURL } from '../../utils';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {ThemeTable, ThemeNotice, ThemeTwitter} from '../../widgets'
import { useAppSelector } from '../../app/hooks';
import { currentLang } from '../../lang/langSlice';


export function Home3() {
    const lang = useAppSelector(currentLang);
    return (
        <GridLayout
            className="layout"
            breakpoints={{ lg: 1300, md: 1000, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 6, sm: 6, xs: 6, xxs: 3 }}
            rowHeight={118}>

            <div
                key="1"
                data-grid={{ w: 3, h: 7, x: 0, y: 0, minW: 3, minH: 1, isDraggable: false}}
            >
                <AtomicCard
                    cardTitle={`Monitor`}>
                    <ThemeTable fullHeight>
                        <FinancialMonitor
                            headerFixed={true}
                            key={`main-FinancialMonitor-${21}`}
                            instance={'3'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>

            <div
                key="2"
                data-grid={{ w: 3, h: 7, x: 3, y: 0, minW: 3, minH: 1, isDraggable: false }}
            >
                <AtomicCard
                    cardTitle={`Noticias`}
                    noScroll
                >
                    <ThemeNotice>
                        <FinancialNotice
                            key={`main-FinancialNotices-${7}`}
                            instance={'7'}
                            language={lang.code}
                        />
                    </ThemeNotice>
                </AtomicCard>
            </div>

            <div
                key="3"
                data-grid={{ w: 3, h: 7, x: 6, y:0, minW: 3, minH: 1, isDraggable: false}}
            >
                <AtomicCard
                    cardTitle={`Top News`}
                    noScroll
                >
                    <ThemeNotice>
                        <FinancialNotice
                            key={`main-FinancialNotices-${22}`}
                            instance={'22'}
                            language={lang.code}
                        />
                    </ThemeNotice>
                </AtomicCard>
            </div>

            <div
                key="4"
                data-grid={{ w: 3, h: 7, x: 9, y: 0, isDraggable: false}}
            >
                <AtomicCard
                    cardTitle={`Twitter`}
                >
                    <ThemeTwitter>
                        <FinancialTwitter
                            key={`main-FinancialTwitter-${23}`}
                            instance={'9'}
                            language={lang.code}
                        />
                    </ThemeTwitter>
                </AtomicCard>
            </div>
        </GridLayout>
    )
}

import React, { useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FinancialAlertHistory, FinancialWatchlist } from 'fs-widgets-core';
import { ThemeTable } from '../../widgets'
import { useAppSelector } from '../../app/hooks';
import { currentLang } from '../../lang/langSlice';
import { CellCtrl } from 'ag-grid-community';
import { createTheme } from '@mui/material';

const HistoryAlertPage = () => {
    const lang = useAppSelector(currentLang);

    useEffect(() => {
        console.log('Init Page watchlist', CellCtrl)
    }, [])

    return (
        <ThemeTable>
            <FinancialAlertHistory 
                instance={"27"}
                language={'es_MX'}
                headerFixed={true}/>
        </ThemeTable>
    )

}

export default HistoryAlertPage;

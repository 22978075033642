import React, { type FC } from 'react'
import {Header} from "./style"
import PropsInterface from "./interface"
import {
    AtomicText,
    AtomicButtonIcon
} from "../../../../atom"
import { X } from 'react-feather';

const HeaderDrawer: FC<PropsInterface> = (props) => {
    const{
        onClose,
        titleDrawer,
        description
    } = props

   
    return(
        <Header className='header'>
            <div>
                <AtomicText  size="14" textnowrap="true" bold>{titleDrawer}</AtomicText>
                {description && <AtomicText  size="11" textnowrap="true">{description}</AtomicText>}
            </div>
            <AtomicButtonIcon
                aria-label="close"
                onClick={onClose}
                size="small"
            >
                <X />
            </AtomicButtonIcon>
        </Header>
    )
}

export default HeaderDrawer;


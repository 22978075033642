import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import ContractModel from './interface/contract.model';
import TraderUserModel from './interface/traderUser.model';
import StockPortfolioModel from './interface/stockPortfolio.model';

import { getContractsService, getListFundsService, getStockPortfolioService, getUserService, makeTransferService, getEquitiesOrdersService, getFundsOrdersService, getPositionsService } from './traderAPI';
import TransferRequestModel, { defaultTransferState, TransferResponseModel } from './interface/transfer.model';
import FundListItem from './interface/fundListItem.model';

export enum ServiceStatus {
  INIT = 'init',
  IDLE = 'idle',
  LOADING = 'loading',
  FAILED = 'failed',
}

type BoletTabType = 'funds'|'equities'|undefined;

export interface TraderState {
  contract: {
    entity: ContractModel | null;
    status: ServiceStatus;
  };
  stockPortfolio: {
    entity: StockPortfolioModel | null;
    status: ServiceStatus;
  }
  contracts: {
    entity: ContractModel[];
    status: ServiceStatus;
  };
  user: {
    entity: TraderUserModel;
    status: ServiceStatus;
  };
  transfer: {
    entity: TransferRequestModel;
    status: ServiceStatus;
    response: TransferResponseModel | null;
  };
  funds: {
    listFunds: FundListItem[];
    selectedFund?: InstrumentKey | null;
    status: ServiceStatus;
    orders: {
      data: any[];
      status: ServiceStatus;
    }
  }
  equities: {
    selectedEquity?: InstrumentKey | null;
    orders: {
      data: any[];
      status: ServiceStatus;
      showCancel: boolean;
    }
  }
  positions: {
    data: any[];
    status: ServiceStatus;
  },
  bolet: {
    tabSelected: BoletTabType
  }
}

export interface InstrumentKey {
  issuer: string;
  serie?: string | null;
  type?: string | null;
  field?: string | null;
}

const initialStatus = ServiceStatus.IDLE;

const initialState: TraderState = {
  contract: {
    entity: null,
    status: initialStatus
  },
  stockPortfolio: {
    entity: null,
    status: initialStatus
  },
  contracts: {
    entity: [],
    status: initialStatus
  },
  user: {
    entity: {
      alias: '',
      id: '',
    },
    status: initialStatus
  },
  transfer: {
    entity: defaultTransferState,
    status: initialStatus,
    response: null
  },
  funds: {
    listFunds: [],
    status: initialStatus,
    selectedFund: null,
    orders: {
      data: [],
      status: initialStatus,
    }
  },
  equities: {
    orders: {
      data: [],
      status: initialStatus,
      showCancel: false,
    }
  },
  positions: {
    data: [],
    status: initialStatus
  },
  bolet: {
    tabSelected: undefined
  }
}


export const getContracts = createAsyncThunk(
  'trader/contracts',
  async (_, { getState }) => {
    const { trader } = getState() as RootState;
    if (typeof trader.user.entity?.alias === 'string') {
      return await getContractsService(trader.user.entity.alias);
    }
    return new Promise<ContractModel[]>(r => r([]));
  }
)

export const getUser = createAsyncThunk(
  'trader/getUser',
  async _ => {
    return await getUserService();
  }
)

export const getListFunds = createAsyncThunk(
  'trader/getListFunds',
  async (_, { getState }) => {
    const { trader } = getState() as RootState;
    const { user, contract } = trader;
    return await getListFundsService(user.entity.alias, contract?.entity?.id);
  }
)

export const getStockPortfolio = createAsyncThunk(
  'trader/getStockPortfolio',
  async (_, { getState }) => {
    const { trader } = getState() as RootState;
    const { user, contract } = trader;
    return await getStockPortfolioService(user.entity.alias, contract.entity?.id!);
  }
)

export const getFundsOrders = createAsyncThunk(
  'trader/getFundsOrders',
  async (payload: { alias: string; contractId: string }) => {
    return await getFundsOrdersService(payload);
  }
)

export const getEquitiesOrders = createAsyncThunk(
  'trader/getEquitiesOrders',
  async (payload: { alias: string; contractId: string }) => {
    return await getEquitiesOrdersService(payload);
  }
)

export const makeTransfer = createAsyncThunk(
  'trader/makeTransfer',
  async (payload: TransferRequestModel) => {
    return await makeTransferService(payload);
  }
)

export const getPositions = createAsyncThunk(
  'trader/getPositions',
  async (_, {getState}) => {
    const { trader } = getState() as RootState;
    if(trader.contract.entity?.id) {
      const payload = {
        alias: trader.user.entity.alias, 
        contractId: trader.contract.entity.id
      }
      try {
        return await getPositionsService(payload);
      } catch (error) {
      }
    }
    return [];
  }
)

export const traderSlice = createSlice({
  name: 'trader',
  initialState,
  reducers: {
    setContract: (state, action: PayloadAction<ContractModel>) => {
      state.contract.entity = action.payload;
    },
    setUser: (state, action: PayloadAction<TraderUserModel>) => {
      state.user.entity = action.payload;
    },
    setTransfer: (state, action: PayloadAction<TransferRequestModel>) => {
      state.transfer.entity = action.payload;
    },
    resetTransferResponse: (state) => {
      state.transfer.response = null;
    },
    setSelectedFund: (state, action: PayloadAction<InstrumentKey | null>) => {
      state.funds.selectedFund = action.payload;
    },
    setSelectedEquity: (state, action: PayloadAction<InstrumentKey | null>) => {
      state.equities.selectedEquity = action.payload;
    },
    setStockPortfolio: (state, action: PayloadAction<StockPortfolioModel>) => {
      state.stockPortfolio.entity = action.payload;
    },
    setCurrentPositions: (state, action: PayloadAction<any[]>) => {
      state.positions.data = action.payload;
    },
    setBoletTab: (state, action: PayloadAction<BoletTabType>) => {
      state.bolet.tabSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContracts.pending, (state) => {
        state.contract.status = ServiceStatus.LOADING;
      })
      .addCase(getContracts.fulfilled, (state, action) => {
        state.contracts.status = ServiceStatus.IDLE;
        state.contracts.entity = action.payload;
      })
      .addCase(getContracts.rejected, (state) => {
        state.contracts.status = ServiceStatus.FAILED;
      })
      .addCase(getUser.pending, (state) => {
        state.user.status = ServiceStatus.LOADING;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user.status = ServiceStatus.IDLE;
        state.user.entity = action.payload;
      })
      .addCase(getUser.rejected, (state) => {
        state.user.status = ServiceStatus.FAILED;
      }).addCase(getStockPortfolio.pending, (state) => {
        state.stockPortfolio.status = ServiceStatus.LOADING;
      })
      .addCase(getStockPortfolio.fulfilled, (state, action) => {
        state.stockPortfolio.status = ServiceStatus.IDLE;
        state.stockPortfolio.entity = action.payload;
      })
      .addCase(getStockPortfolio.rejected, (state) => {
        state.stockPortfolio.status = ServiceStatus.FAILED;
      })
      .addCase(makeTransfer.pending, (state) => {
        state.transfer.status = ServiceStatus.LOADING;
      })
      .addCase(makeTransfer.fulfilled, (state, action) => {
        state.transfer.status = ServiceStatus.IDLE;
        state.transfer.response = action.payload;
      })
      .addCase(makeTransfer.rejected, (state) => {
        state.transfer.status = ServiceStatus.FAILED;
      })
      .addCase(getListFunds.pending, (state) => {
        state.funds.status = ServiceStatus.LOADING;
        state.funds.listFunds = [];
      })
      .addCase(getListFunds.fulfilled, (state, action) => {
        state.funds.status = ServiceStatus.IDLE;
        state.funds.listFunds = action.payload;
      })
      .addCase(getListFunds.rejected, (state) => {
        state.funds.status = ServiceStatus.FAILED;
        state.funds.listFunds = [];
      })
      .addCase(getFundsOrders.pending, (state) => {
        state.funds.orders.status = ServiceStatus.LOADING;
        state.funds.orders.data = [];
      })
      .addCase(getFundsOrders.fulfilled, (state, action) => {
        state.funds.orders.status = ServiceStatus.IDLE;
        state.funds.orders.data = action.payload;
      })
      .addCase(getFundsOrders.rejected, (state) => {
        state.funds.orders.status = ServiceStatus.FAILED;
        state.funds.orders.data = [];
      })
      .addCase(getEquitiesOrders.pending, (state) => {
        state.equities.orders.status = ServiceStatus.LOADING;
        state.equities.orders.data = [];
      })
      .addCase(getEquitiesOrders.fulfilled, (state, action) => {
        state.equities.orders.status = ServiceStatus.IDLE;
        state.equities.orders.data = action.payload;
        state.equities.orders.showCancel = action.payload.some(item => item.field_status.toLowerCase() === 'registrada') || (action.payload.some(item => item.showCancel) && action.payload.every(item => String(item.field_status).toLowerCase() !== 'cancelada'))
      })
      .addCase(getEquitiesOrders.rejected, (state) => {
        state.equities.orders.status = ServiceStatus.FAILED;
        state.equities.orders.data = [];
      })
      .addCase(getPositions.pending, (state) => {
        state.positions.data = [];
        state.positions.status = ServiceStatus.LOADING;
      })
      .addCase(getPositions.fulfilled, (state, action) => {
        state.positions.data = action.payload;
        state.positions.status = ServiceStatus.IDLE;
      })
      .addCase(getPositions.rejected, (state) => {
        state.positions.data = [];
        state.positions.status = ServiceStatus.FAILED;
      });

  },
});


const fmt = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

export const moneyFmt = (value: any) => {
  if (isNaN(value)) {
    return fmt.format(0);
  }
  return fmt.format(value);
}

export const { setContract, setUser, setTransfer, resetTransferResponse, setSelectedFund, setSelectedEquity, setStockPortfolio, setCurrentPositions, setBoletTab } = traderSlice.actions;

export const selectCurrentContract = (state: RootState) => state.trader.contract.entity;
export const selectContracts = (state: RootState) => state.trader.contracts.entity;
export const selectCurrentUser = (state: RootState) => state.trader.user.entity;
export const selectStockPorfolio = (state: RootState) => state.trader.stockPortfolio.entity;
export const selectTransferState = (state: RootState) => state.trader.transfer;
export const selectFundsState = (state: RootState) => state.trader.funds;
export const selectEquitiesState = (state: RootState) => state.trader.equities;
export const selectPositionsState = (state: RootState) => state.trader.positions;
export const selectBoletState = (state: RootState) => state.trader.bolet;

export default traderSlice.reducer;
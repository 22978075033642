import { type FC } from 'react'
import {Input} from "./style"
import InputPropsInterface from './interface';

const AtomicInputText:FC<InputPropsInterface> = (props) => {

    const {
        id,
        label,
        variant,
        InputProps,
        fullWidth,
        className,
        multiline,
        maxRows,
        value,
        defaultValue,
        onChange,
        select,
        disabled,
        error,
        helperText,
        placeholder,
        children,
        ...params
    } = props;

    return (
        <Input 
            id={id} 
            label={label}
            variant={variant ? variant : "standard"}
            select={select}
            InputProps={InputProps}
            fullWidth={fullWidth}
            className={className}
            InputLabelProps={{
              shrink: true,
            }}
            multiline={multiline}
            maxRows={maxRows}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            {...params}
        >
            {children}
        </Input>
    )
}


export default AtomicInputText;
import React, { useState, useEffect } from "react";
import { AtomicCard } from "../../atom"
import GridLayout from "../../atom/gridLayout"
import { FinancialOrders, FinancialPortfolio, FinancialPositions, FinancialTransactionHistory } from 'fs-widgets-core'
import { selectCurrentContract, selectCurrentUser, getListFunds, getPositions, getFundsOrders, getEquitiesOrders, setSelectedEquity, setSelectedFund, selectFundsState, selectEquitiesState, selectPositionsState, getStockPortfolio, selectTransferState } from "../../trader/traderSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import moment from "moment";
import { getStockPortfolioService, getPortfolioDetailService, getPortfolioHistoryService, getTransactionsService } from "../../trader/traderAPI";
import { ThemeChart, ThemeTable } from "../../widgets";
import { currentLang } from "../../lang/langSlice"
import { decimalFmt } from "../../utils";

const colors = ["#8B8B8B", "#002E9D", "#008CC0", "#EB5757"];

export default function PortfolioPage() {

  const dispatch = useAppDispatch();
  const [portfolioData, setPortfolioData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const lang = useAppSelector(currentLang);
  const currentContract = useAppSelector(selectCurrentContract);
  const user = useAppSelector(selectCurrentUser);
  const contract = useAppSelector(selectCurrentContract);
  const fundsState = useAppSelector(selectFundsState);
  const equitiesState = useAppSelector(selectEquitiesState);
  const transferState = useAppSelector(selectTransferState);
  const [transactionHistory, setTransactionHistory] = useState<any>();
  const [typeSelect, setTypeSelect] = React.useState('CAPITALES');
  const [typeTransactionDate, setTypeTransactionDate] = React.useState('OPERACION');
  const [startDate, setStartDate] = React.useState(new Date().toISOString());
  const [endDate, setEndDate] = React.useState(new Date().toISOString());
  useEffect(() => {
    if (user?.alias && contract?.id) {
      dispatch(getListFunds());
      dispatch(getPositions());
    }
  }, [user, contract]);

  //Financial orders
  const getDataEquitiesOrders = () => {
    const alias = user.alias;
    const contractId = contract?.id;
    if (alias && contractId) {
      dispatch(getFundsOrders({ alias, contractId }))
    }
  }
  const getDataFundsOrders = () => {
    const alias = user.alias;
    const contractId = contract?.id;
    if (alias && contractId) {
      dispatch(getEquitiesOrders({ alias, contractId }))
    }
  }
  const handleSelectInstrument = (payload: { type: 'fund' | 'equity', issuer: string; serie: string }) => {
    const { issuer, serie } = payload;
    switch (payload.type) {
      case 'equity':
        dispatch(setSelectedEquity({ issuer, serie }))
        break;
      case 'fund':
        dispatch(setSelectedFund({ issuer, serie }));
        break;
    }
  }
  
  //// FINANCIAL POSITIONS
  const {
    status: statusPositions,
    data: positions
  } = useAppSelector(selectPositionsState);

  /// PORTFOLIO DATA
  const getPortfolioData = async () => {
    const alias = user.alias;
    const contractId = contract!.id;
    const responsePortfolio: any = await getStockPortfolioService(alias, contractId, true);
    const portfolioDataBuild: any = {
      total: '',
      percent: '',
      balanceAvailable: '',
      date: '',
      dataTable: [],
      summary: [],
      summaryColumns: [
        {
          field: 'field_currency',
          headerName: 'Divisa'
        },
        {
          field: 'field_balanceMd',
          headerName: 'Saldo MD',
        },
        {
          field: 'field_oneDayBalance',
          headerName: 'Saldo 24hrs.',
        },
        {
          field: 'field_twoDayBalance',
          headerName: 'Saldo 48hrs.',
        },
        {
          field: 'field_balanceBlocked',
          headerName: 'Saldo bloqueado',
        },
        {
          field: 'field_balanceInTransit',
          headerName: 'Saldo en transito',
        },
        {
          field: 'field_balanceNotAvailable',
          headerName: 'No disponible',
        },
        {
          field: 'field_valuationMxp',
          headerName: 'Val. en MXP',
        },
        {
          field: 'field_netBalance',
          headerName: 'Saldo Neto',
          cellClass: 'ag-right-aligned-cell',
          headerClass: 'ag-right-aligned-header',
        },
      ],
      balanceHistory: []
    }

    try {
      if (responsePortfolio) {

        portfolioDataBuild.total = (responsePortfolio.valorCartera)
        portfolioDataBuild.percent = responsePortfolio.porcentajeCartera
        portfolioDataBuild.balanceAvailable = (responsePortfolio.clasificacion.efectivo)
        portfolioDataBuild.date = moment().format('DD/MM/YYYY HH:mm')
        portfolioDataBuild.dataTable.push({
          name: 'Capitales',
          amount: (responsePortfolio.clasificacion.capitales),
          percent: responsePortfolio.clasificacion.pctCapitales,
          color: colors[0],
        })
        portfolioDataBuild.dataTable.push({
          name: 'Dinero',
          amount: (responsePortfolio.clasificacion.dinero),
          percent: responsePortfolio.clasificacion.pctDinero,
          color: colors[1],
        })
        portfolioDataBuild.dataTable.push({
          name: 'Efectivo',
          amount: (responsePortfolio.clasificacion.efectivo),
          percent: responsePortfolio.clasificacion.pctEfectivo,
          color: colors[2],
        })
        portfolioDataBuild.dataTable.push({
          name: 'Fondos',
          amount: (responsePortfolio.clasificacion.fondos),
          percent: responsePortfolio.clasificacion.pctFondos,
          color: colors[3],
        })
      }
    } catch (e){

    }

    try {
      const responsePortfolioDetail: any = await getPortfolioDetailService(alias, contractId);

      if (responsePortfolioDetail.status === 200) {

        responsePortfolioDetail.data.fondos?.map((data: any, idx: number) => {
          portfolioDataBuild.summary.push({
            field_currency: data.descDivisa,
            field_oneDayBalance: data.saldoAunDia,
            field_twoDayBalance: data.saldoAdosDias,
            field_balanceBlocked: data.saldoBloqueado,
            field_balanceInTransit: data.saldoEnTransito,
            field_balanceMd: data.saldoMd,
            field_netBalance: data.saldoNeto,
            field_balanceNotAvailable: data.saldoNoDisponible,
            field_valuationMxp: data.valuacionMxp,
            id: idx,
          })
        })
      }
    } catch (e) {

    }

    try {
      const responsePortfolioHistory: any = await getPortfolioHistoryService(alias, contractId);

      if (responsePortfolioHistory.status === 200) {


        responsePortfolioHistory.data.saldos?.map((data: any) => {
          portfolioDataBuild.balanceHistory.push({
            open: data.valor,
            high: data.valor,
            low: data.valor,
            close: data.valor,
            date: data.fecha,
            dateObj: moment(data.fecha, 'YYYY-MM-DD').toDate()
          })
        })
      }

    } catch (e) {

    }
    setPortfolioData(portfolioDataBuild);

  }

  // Financial Transactions
  const getDataTransactions = async () => {
    const payload = {
        fechaInicio: moment(startDate).format('YYYY-MM-DD'),
        fechaFin: moment(endDate).format('YYYY-MM-DD'),
        cveMercado: typeSelect,
        tipoFecha: typeTransactionDate
    }
    const response: any = await getTransactionsService(user?.alias, contract?.id!, payload);

    const transactionHistoryBuild: any = {
        dataTable: []
    }

    if (response?.status === 200 && Array.isArray(response.data?.transacciones)) {
        (response.data.transacciones as any[]).map((data: any, idx: number) => {
            transactionHistoryBuild.dataTable.push({
                field_settlementDate: data.fLiquidacion,
                field_operationDate: data.fOperacion,
                field_transactionDesc: data.descTransaccion,
                field_issuerSerie: data.emisoraSerie,
                field_company: data.empresa,
                field_operationCve: data.cveOperacion,
                field_marketCve: data.cveMercado,
                field_grossTax: data.impBruto,
                field_taxCommission: data.impComision,
                field_taxInterest: data.impIntereses,
                field_taxIsr: data.impIsr,
                field_taxIva: data.impIva,
                field_netTax: data.impNeto,
                field_operationPrec: data.precOperacion,
                field_titles: data.titulos,
                field_currency: data.cveDivisa,
                id: idx
            })
        })

    }
    setTransactionHistory(transactionHistoryBuild)
}

  const headerProps = {
    typeSelect, setTypeSelect,
    startDate, setStartDate,
    endDate, setEndDate,
    typeTransactionDate, setTypeTransactionDate,
}

  useEffect(() => {
    if (!currentContract?.id || !user?.alias) {
      return;
    }
    const init = async () => {
      setIsLoading(true);
      await getPortfolioData();
      setIsLoading(false);
    }
    init();
    const intervalId = setInterval(getPortfolioData, 60*1000);
    return () => clearInterval(intervalId);
  }, [currentContract?.id, user?.alias]);

  useEffect(() => {
    if(transferState.response) {
      getPortfolioData();
    }
  }, [transferState.response]);

  useEffect(() => {
    if (!user?.alias || !contract?.id) {
        return;
    }
    (async () => {
        setIsLoading(true);
        await getDataTransactions();
        setIsLoading(false);
    })();        
}, [user, contract?.id, typeSelect, startDate, endDate, typeTransactionDate]);

  const updateData = () => {
    dispatch(getStockPortfolio());
    dispatch(getListFunds());
    dispatch(getPositions());
    getDataEquitiesOrders();
    getDataFundsOrders();
    getPortfolioData();
    getDataTransactions();
  }

  return (
    <GridLayout
      className="layout"
      breakpoints={{ lg: 1300, md: 1000, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 12, sm: 6, xs: 6, xxs: 6  }}
      rowHeight={51}>
        
      <div
        key="1"
        data-grid={{ w: 12, h: 14, x: 0, y: 0, isDraggable: false }}
      >
        <ThemeTable>
          <FinancialPortfolio
            instance="financial-portfolio"
            portfolioData={portfolioData}
            isLoading={isLoading}
          />
        </ThemeTable>
      </div>
      <div
        key="2"
        data-grid={{ w: 6, h: 7, x: 0, y: 14, isDraggable: false }}
      >
        <AtomicCard
          cardTitle={`Posiciones`}
        >
          <ThemeTable fullHeight>
            <FinancialPositions
              headerFixed={true}
              instance='financial-positions'
              language={lang.code}
              url={`${process.env.REACT_APP_TRADER_SERVICES}`}
              positions={positions}
              status={statusPositions}
              handleOnClickRow={handleSelectInstrument}
            />
          </ThemeTable>
        </AtomicCard>
      </div>
      <div
        key="3"
        data-grid={{ w: 6, h: 7, x: 6, y: 14, isDraggable: false }}
      >
        <AtomicCard
          cardTitle={`Órdenes`}
        >
          <ThemeTable fullHeight>
            <FinancialOrders
              instance="financial-orders"
              url={`${process.env.REACT_APP_TRADER_SERVICES}/orders`}
              language={lang.code}
              headerFixed={true}
              contract={{
                alias: user?.alias,
                contractId: contract?.id
              }}
              getDataEquitiesOrders={getDataEquitiesOrders}
              getDataFundsOrders={getDataFundsOrders}
              equitiesOrders={equitiesState.orders}
              fundsOrders={fundsState.orders}
              setSelectedInstrument={handleSelectInstrument}
              onUpdateOrders={updateData}
              showCancelAll={equitiesState.orders.showCancel}
            />
          </ThemeTable>
        </AtomicCard>
      </div>
      <div
        key="4"
        data-grid={{ w: 12, h: 13, x: 0, y: 0, isDraggable: false }}
      >
        <ThemeTable>
          <FinancialTransactionHistory
            instance={"financial-transaction-history"}
            language={lang.code}
            theme={""}
            transactionHistory={transactionHistory}
            headerProps={headerProps}
            isLoading={isLoading}
            header
            cardTitle={'Historial de Transacciones'}
          />
        </ThemeTable>
      </div>
    </GridLayout>
  )
}
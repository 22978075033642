import { type FC } from 'react'
import Lottie from "lottie-react";
import animation from "./animation.json";
import LoaderPropsProperties from "./interface";
import {ContainerLoader} from "./style";

const AtomicLoader: FC<LoaderPropsProperties> = (props) => {
  const { 
    loading,
    loop
  } = props;

  return ( loading ? <ContainerLoader>
          <Lottie animationData={animation} loop={loop} style={{ height: '300px', width: '300px' }}/>
      </ContainerLoader> : null
    )
};

export default AtomicLoader;

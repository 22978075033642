
import React from 'react';
import { FinancialDashboard } from 'fs-widgets-core';
import { 
    ThemeDash, 
    ThemeBolet, 
    ThemeChart, 
    ThemeTable,
    ThemeTicker
 } from '../../widgets'

export function Dash () {

    return (
        <ThemeTicker>
        <ThemeTable>
        <ThemeBolet>
        <ThemeDash>
        <ThemeChart>
            <FinancialDashboard headerFixed={true}/>
        </ThemeChart>
        </ThemeDash>
        </ThemeBolet>
        </ThemeTable>
        </ThemeTicker>
    )

}


import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentTechnicalAnalysis = styled.div`
    height: 100% !important;
    margin-right: -10px;
    .layout-technical{
        /* search special */
        div[class*="control"]{
            ${FontSize[11]};
            >div[class*="ValueContainer"], >div:first-child{
                div{
                    color:  ${props => props.theme.palette.layout.text} !important;
                }
                div[class*="placeholder"]{
                    color:  ${props => props.theme.palette.layout.text} !important;
                    ${FontSize[11]};
                }
                div[class*="Input"]{
                    color:  ${props => props.theme.palette.layout.text} !important;
                }
            }
            >div[class*="IndicatorsContainer"], >div:last-child{
                div[class*="indicatorContainer"]{
                    color:  ${props => props.theme.palette.layout.text} !important;
                }
            }
        }
        ~ div[class*="MenuPortal"], div[class*="menu"]  {
            > div{
                background-color: ${props => props.theme.palette.card.header} !important;
            }
        }
        
        .btn-header{
            color: ${props => props.theme.palette.layout.text} !important;
            &:hover{
                background-color: ${props => props.theme.palette.card.hover} !important;
                color: ${props => props.theme.palette.colors.secondary} !important;
            }
            &.fill-color{
                svg{
                    fill: ${props => props.theme.palette.layout.text} !important;
                }
                &:hover{
                    svg{
                        fill: ${props => props.theme.palette.colors.secondary} !important;
                    }
                }
            }
        }
        .headerActions{
            border-color: ${props => props.theme.palette.line.gray50} !important;
            
            div[class*="ScrollButton"]{
                svg{
                    color:  ${props => props.theme.palette.layout.text} !important;
                }
            }
            .content-lol{
                border-right-color: ${props => props.theme.palette.line.gray50} !important;
            }
            .header-flex{
                >div{
                    &.left{
                        >div:first-child{
                            div[class*="control"]{
                                border-bottom-color: ${props => props.theme.palette.colors.secondary} !important;
                            }
                        }
                        div[class*="control"]{
                            border-right-color: ${props => props.theme.palette.line.gray50} !important;
                            &:hover{
                                border-color: ${props => props.theme.palette.colors.secondary} !important;
                            }
                            span[class*="indicatorSeparator"]{
                                background-color: ${props => props.theme.palette.line.gray50} !important;
                            }
                        }
                        .btn-header{
                            border-right-color: ${props => props.theme.palette.line.gray50} !important;
                        }
                    }
                    &.right{
                        .btn-header{
                            border-left-color: ${props => props.theme.palette.line.gray50} !important;
                        }
                                .customBtn{
                                    color: ${props => props.theme.palette.layout.text} !important;
                                    border-left-color: ${props => props.theme.palette.line.gray50} !important;
                                    svg{
                                        width: 18px;
                                        height: 18px;
                                    } 
                                    
                                    &.customBtn{
                                        &:hover{
                                            background-color: ${props => props.theme.palette.card.hover} !important;
                                            color: ${props => props.theme.palette.colors.secondary} !important;
                                        }
                                    }
                                }
                    }
                }
            }
        }
        .main{
            .wrapper-menu-left{
                .menu-left{
                    background-color: ${props => props.theme.palette.layout.bg} !important;
                    .btn-menu{
                        border-color: ${props => props.theme.palette.line.gray50} !important;
                        border-bottom: none;
                        &:first-child{
                            border-top: none;
                        }
                        &:last-child{
                            border-bottom: 1px solid;
                            border-bottom-color: ${props => props.theme.palette.line.gray50} !important;
                        }
                        &.fillIcon{
                            svg{
                                fill: ${props => props.theme.palette.layout.text} !important;
                            }
            
                            &:hover{
                                background-color: ${props => props.theme.palette.card.hover} !important;
                                svg{
                                    fill: ${props => props.theme.palette.colors.secondary} !important;
                                }
                            }
                            &.active{
                                svg{
                                    fill: ${props => props.theme.palette.colors.secondary} !important;
                                }
                            }
                        }
                        svg{
                            color: ${props => props.theme.palette.layout.text} !important;
                        }
        
                        &:hover{
                            background-color: ${props => props.theme.palette.card.hover} !important;
                            svg{
                                color: ${props => props.theme.palette.colors.secondary} !important;
                            }
                        }
                        &.active{
                            svg{
                                color: ${props => props.theme.palette.colors.secondary} !important;
                            }
                        }
                    }
                }
                .atomic-drawer{
                    [class*="MuiDrawer-paper"]{
                        background-color: ${props => props.theme.palette.layout.bg} !important;
                        color: ${props => props.theme.palette.layout.text} !important;
                        
                        .use-icons{
                            background: ${props => props.theme.palette.accordion.headerInnerBg} !important;
                            button{
                                color: ${props => props.theme.palette.accordion.headerInnerText} !important;
                            }
                        }
                        .body-use-icons{
                            button{
                                color: ${props => props.theme.palette.layout.text} !important;
                            }
                        }
                    }
                    .style-accordion{
                        [class*="MuiAccordionSummary"]{
                            background: ${props => props.theme.palette.accordion.headerBg} !important;
                            color: ${props => props.theme.palette.accordion.headerText} !important;
                            > .head{
                                button{
                                    color: ${props => props.theme.palette.accordion.headerText} !important;
                                }
                            }
                        }
                        [class*="MuiAccordionDetails"]{
                            color: ${props => props.theme.palette.layout.text} !important;
                            .bg-box-item{
                                background: ${props => props.theme.palette.accordion.headerInnerBg} !important;
                                color: ${props => props.theme.palette.accordion.headerInnerText} !important;
                                button{
                                    color: ${props => props.theme.palette.accordion.headerInnerText} !important;
                                }
                            }
                            [class*="MuiAccordionSummary"]{
                                background: ${props => props.theme.palette.accordion.headerInnerBg} !important;
                                color: ${props => props.theme.palette.accordion.headerInnerText} !important;
                                > .head{
                                    button{
                                        color: ${props => props.theme.palette.accordion.headerInnerText} !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .content-chart{
                background-color: transparent !important;
                color: ${props => props.theme.palette.layout.text} !important;
                >div{
                    z-index: 0;
                }
                .react-financial-charts-tooltip{
                    > g:first-child{
                        rect{
                            stroke: ${props => props.theme.palette.colors.secondary} !important;
                            stroke: none !important;
                            fill: ${props => props.theme.palette.layout.bg} !important;
                            fill: transparent !important;
                            filter: drop-shadow(2px 4px 6px transparent);
                        }
                    }
                    tspan:not(.react-financial-charts-tooltip-label){
                        fill: ${props => props.theme.palette.layout.text} !important;
                    }
                }
            }
        }
    }
    .compact-picker{
        background-color: ${props => props.theme.palette.configChart.bgColorPicker} !important;
        input, label{
            color: ${props => props.theme.palette.configChart.colorPicker} !important;
        }
    }
    .button-select{
        background-color: ${props => props.theme.palette.configChart.selectBg} !important;
        color: ${props => props.theme.palette.configChart.selectColor} !important;
        > div{
            border-color: ${props => props.theme.palette.configChart.selectColor} !important;
        }
        .icon-line{
            stroke: ${props => props.theme.palette.configChart.selectColor} !important;
        }
    }
    .counter{
        .conteiner-counter{
            button{
                background-color: ${props => props.theme.palette.colors.secondary} !important;
            }
            .center-input{
                background-color: ${props => props.theme.palette.configChart.inputCounter} !important;
                border-color: ${props => props.theme.palette.configChart.borderInputCounter} !important;
                input{
                    color: ${props => props.theme.palette.layout.text} !important;
                }
            }
        }
    }
           
`;

export { ContentTechnicalAnalysis };
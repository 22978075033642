import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentChart = styled.div`
    height: 100%;
    >div{
        background-color: transparent !important;
        g[role*="menuitem"]{
            stroke: ${props => props.theme.palette.layout.bg};
        }
        g[role*="listitem"]{
            stroke: ${props => props.theme.palette.layout.bg};
        }
        text{
            font-family: 'Roboto';
            ${FontSize[11]};
        }
        .name-font {
            font-family: 'Raleway' !important;
            text{
                font-family: 'Raleway' !important;
                font-size: 12px !important;
                fill: white !important;
            }
        }
        .value-font {
            font-family: 'Roboto' !important;
            text{
                font-family: 'Roboto' !important;
                font-size: 16px !important;
                fill: white !important;
            }
        }
        .tooltip-heatMap-see-later{
            font-family: 'Roboto' !important;
            color: white !important;
            font-size: 12px !important;
            margin-bottom: 5px !important;
            display: inline-block;
        }
    }
    
`;

export { ContentChart };
import React, { type FC } from 'react'
import {
    CardStyle, 
    CardContentStyle, 
    CardHeaderStyle
} from './style';
import {CardPropsProperties, CardHeaderProps} from './interface';
import {AtomicButtonIcon, AtomicText, AtomicTooltip} from ".."
import { XCircle, Settings } from 'react-feather';
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';

const AtomicCardHeader: FC<CardHeaderProps> = (props) => {
    const theme = useAppSelector(currentTheme);
    const{
        onClose,
        cardTitle,
        configWidget,
        children,
        hoverable,
        openDrawer
    } = props
    return (
        <CardHeaderStyle theme={theme} className={hoverable ? "hoverable" : null}>
            {children}
            {cardTitle && 
                <AtomicText nomargin textnowrap='true'>{cardTitle}</AtomicText>
            }
            <div className="actions">
                {configWidget && (
                    <AtomicTooltip title='Configuración'>
                        <AtomicButtonIcon
                            aria-label="close"
                            onClick={() => openDrawer()}
                            size="small"
                            mr
                        >
                            <Settings />
                        </AtomicButtonIcon>
                    </AtomicTooltip>
                )}
                {onClose && <AtomicTooltip title='Eliminar'>
                    <AtomicButtonIcon
                        aria-label="close"
                        onClick={onClose}
                        size="small"
                    >
                        <XCircle />
                    </AtomicButtonIcon>
                </AtomicTooltip>}
            </div>
        </CardHeaderStyle>
    )

}


const AtomicCard: FC<CardPropsProperties> = (props) => {
    const theme = useAppSelector(currentTheme);
    
    const {
        cardTitle,
        children,
        onClose,
        noScroll,
        configWidget,
        tabs,
        openDrawer
    } = props

    return (
            <CardStyle theme={theme}>
                <AtomicCardHeader cardTitle={cardTitle} configWidget={configWidget} openDrawer={openDrawer} onClose={onClose}>{tabs}</AtomicCardHeader>
                <CardContentStyle noScroll={noScroll}>
                    {children}
                </CardContentStyle>
            </CardStyle>
    )
}

export default AtomicCard;
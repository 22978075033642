import { createGlobalStyle } from "styled-components";
import { FontSize } from "./utils/Constants/FontSize";

const GlobalStyles = createGlobalStyle<any>`
  body {
    margin: 0;
  }
  
      /* SELECT MENU LIST */
      div[class*="MuiMenu-paper"]{
            background-color: ${props => props.theme.palette.dialog.bg} !important;
            ul{
                  li{
                        ${FontSize[11]};
                        background-color: ${props => props.theme.palette.dialog.bg} !important;
                        color:  ${props => props.theme.palette.dialog.text} !important;
                        [class*="MuiListItemIcon"]{
                              svg{ 
                                    fill:  ${props => props.theme.palette.dialog.text} !important;
                              }
                              [class*="MuiCheckbox"]{
                                    > input ~ svg{
                                          fill:  ${props => props.theme.palette.colors.secondary} !important;
                                    }
                              }
                        }
                  }
            }
      }
      /* END SELECT MENU LIST */
      [class*="MuiCheckbox"]{
            > input ~ svg{
                  fill:  ${props => props.theme.palette.colors.secondary} !important;
            }
      }
      /* Etiqueta Radio */
      [class*="MuiFormControlLabel"]{
            &[class*="MuiTypography"]{
                  color: ${props => props.theme.palette.layout.text} !important;
            }
      }
      /* DIALOG */
      .MuiDialog-container{
            div[class*="paper"]{
                  background-color: ${props => props.theme.palette.dialog.bg} !important;
                  color:  ${props => props.theme.palette.dialog.text} !important;
                  h2{
                        ${FontSize[13]};
                        button{
                              svg{
                                    color:  ${props => props.theme.palette.dialog.text} !important;
                              }
                        }
                  }
                  .selected-instrument{
                        ${FontSize[11]};
                  }
                  div[class*="MuiDialogContent"]{
                        ${FontSize[11]};
                        color:  ${props => props.theme.palette.dialog.text} !important;
                        
                        [class*="MuiDialogContentText"]{
                              ${FontSize[11]};
                              color:  ${props => props.theme.palette.dialog.text} !important;
                        }
                        /* bodyModal */
                        div[class*="MuiFormControl"]{
                              label{
                                    ${FontSize[12]};
                                    color:  ${props => props.theme.palette.dialog.text} !important;
                              }
                              div[class*="MuiInputBase"]{
                                    
                                    ${FontSize[11]};
                                    color:  ${props => props.theme.palette.dialog.text} !important;
                                    input{
                                          ${FontSize[11]};
                                          color:  ${props => props.theme.palette.dialog.text} !important;
                                    }
                                    svg{
                                          color:  ${props => props.theme.palette.dialog.text} !important;
                                    }
                              }
                              [class*="disabled"]{
                                    -webkit-text-fill-color: ${props => props.theme.palette.dialog.text} !important;
                              }
                        }
                        /* TABLE INNER DIALOG*/
                        .ag-theme-alpine{
                              div{
                                    .ag-header-row{
                                          color: ${props => props.theme.palette.dialog.tableHeaderText} !important;
                                    }
                                    .ag-cell{
                                          color: ${props => props.theme.palette.dialog.text} !important;
                                    }
                                    .ag-row-even{
                                          background-color: ${props => props.theme.palette.dialog.tableRow} !important;
                                    }
                                    .ag-header-select-all{
                                          .ag-checkbox-input-wrapper{
                                                &::after{
                                                      color: ${props => props.theme.palette.colors.secondary} !important;
                                                }
                                          }
                                    }
                                    .ag-checkbox-input-wrapper{
                                          &::after{
                                                color: ${props => props.theme.palette.colors.secondary} !important;
                                          }
                                    }
                                    .ag-select .ag-picker-field-wrapper{
                                          border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                                    }
                                    .grid-content-input{
                                          border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                                    }
                                    .ag-popup-child{
                                          background-color: ${props => props.theme.palette.dialog.bg} !important;
                                          color:  ${props => props.theme.palette.dialog.text} !important;
                                    }
                              }
                        }
                        /* END TABLE INNER DIALOG */
                  }
                  div[class*="MuiDialogActions"]{
                  }
            }
      }
      /* END DIALOG */

      /* SEARCH-SPECIAL */
      div[class*="control"]{
            ${FontSize[11]};
            >div[class*="ValueContainer"], >div:first-child{
                  div{
                        color:  ${props => props.theme.palette.dialog.text} !important;
                  }
                  div[class*="placeholder"]{
                        ${FontSize[11]};
                        color:  ${props => props.theme.palette.dialog.text} !important;
                  }
                  div[class*="Input"]{
                        color:  ${props => props.theme.palette.dialog.text} !important;
                  }
                  div[class*="multiValue"]{
                        background-color: ${props => props.theme.palette.colors.secondary} !important;
                        >div:last-child{
                              color: #FFFFFF !important;
                        }
                        .itemSelect{
                              .left{
                                    div{
                                          color:  #FFFFFF !important; 
                                    }
                              }
                        }
                  }
            }
            >div[class*="IndicatorsContainer"], >div:last-child{
                  div[class*="indicatorContainer"], div{
                        color: ${props => props.theme.palette.dialog.text} !important;
                  }
            }
            
            &~ div[class*="MenuPortal"], &~ div {
                  font-size: 12px !important;
                  background-color: ${props => props.theme.palette.dialog.bg} !important;
                  > div > div{
                        background-color: ${props => props.theme.palette.dialog.bg} !important;
                        > div, div[class*="NoOptionsMessage"] {
                              font-size: 12px !important;
                        }
                        .itemSelect{
                              .left{
                                    div{
                                          font-family: 'Trebuchet MS' !important;
                                          font-weight: 700;
                                          color:  rgba(255,255,255,0.8) !important; 
                                    }
                                    .name{
                                          font-weight: 400;
                                          color:  rgba(255,255,255,0.8) !important; 
                                    }
                              }
                        }
                  }
            }
      }
      /* END SEARCH-SPECIAL */

      /* SEARCH-WIDGET */
      span.result{
            ${FontSize[10]};
      }
      .itemSearch{
            padding: 6px 5px !important;
            .left{
                  .ticker{
                        ${FontSize[11]};
                  }
                  .tikerName{
                        ${FontSize[11]};
                  }
            }
      }
      /* END SEARCH-WIDGET */

      
      
      /* TABLE */
      .ag-theme-alpine{
            div{
                  .ag-header-row{
                        color: ${props => props.theme.palette.table.headerText} !important;
                  }
                  .ag-cell{
                        ${FontSize[11]};
                        color: ${props => props.theme.palette.table.text} !important;
                  }
                  .ag-cell-wrap-text{
                        word-break: break-word;
                  }
                  .ag-row-even{
                        background-color: ${props => props.theme.palette.table.row} !important;
                  }
                  .ag-header-select-all{
                        .ag-checkbox-input-wrapper{
                              &::after{
                                    color: ${props => props.theme.palette.colors.secondary} !important;
                              }
                        }
                  }
                  .ag-checkbox-input-wrapper{
                        &::after{
                              color: ${props => props.theme.palette.colors.secondary} !important;
                        }
                  }
                  .ag-select .ag-picker-field-wrapper{
                        background-color: transparent !important;
                        border-color: ${props => props.theme.palette.line.border} !important;
                  }
                  .grid-content-input{
                        ${FontSize[11]};
                        border-color: ${props => props.theme.palette.line.border} !important;
                  }
                  .ag-popup-child{
                        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14), 0px 3px 14px 2px rgb(0, 0, 0, .12) !important;
                        background-color: ${props => props.theme.palette.dialog.bg} !important;
                        color:  ${props => props.theme.palette.dialog.text} !important;
                        padding: 5px;
                        font-family: Roboto;
                  }
                  
            }
      }
      /* END TABLE */
      
    .wrapper-no-data{
        color: ${props => props.theme.palette.layout.text} !important;
        .message{
            ${FontSize[10]};
        }
    }

      /* SCROLL */
      *{
            scrollbar-color: rgba(0,0,0,0.5) rgba(0,0,0,0) !important;
            scrollbar-width: thin !important;
      }

      ::-webkit-scrollbar, 
      * ::-webkit-scrollbar {
            all:unset;
            width: 8px;
            height: 8px;
      }

      ::-webkit-scrollbar:window-inactive {
            display: none;
      }

      ::-webkit-scrollbar:corner-present  {}

      ::-webkit-scrollbar-track-piece {
            border-radius: 2px;
            background-color: ${props => props.theme.palette.scroll.track};
            background-clip: content-box;
            border-radius: 6px;
            border: solid 2.75px transparent;
      }

      ::-webkit-scrollbar-track-piece:decrement {
            background: ${props => props.theme.palette.scroll.track};
            background-clip: content-box;
            border: solid 2.75px transparent;
      }

      ::-webkit-scrollbar-thumb  {
            height: 8px;
            width: 2px;
            background-color: ${props => props.theme.palette.scroll.bg};
            border-radius: 6px;
            box-shadow: 0 3px 6px -2px rgba(51,0,51,0.3);
      }

      ::-webkit-scrollbar-corner {
            background: transparent ;
      }

      ::-webkit-scrollbar-track-piece:no-button {
            background-color: ${props => props.theme.palette.scroll.track};
      }

      /* END SCROLL */

      /* ALERTS */
      .snack-alert{
            font-family: Raleway !important;
      }
      .snack-title-alert{
            font-family: Raleway !important;
      }

      /* VIDEO */
      .video-react-control-bar{
            div[class*="control"]{
                  &~ div {
                        background-color: transparent !important;
                  }
            }
      }
`;

export default GlobalStyles;

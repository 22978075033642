import { FinancialHeatMap, FinancialSimpleChart, FinancialWinnersLosers } from 'fs-widgets-core';
import {
    AtomicCard,
    GridLayout
} from "../../atom"
import { getBaseURL } from '../../utils';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {ThemeTable, ThemeChart, ThemeHeatMap} from '../../widgets'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { currentLang } from '../../lang/langSlice';
import { setSelectedEquity } from '../../trader/traderSlice';


export function Home2() {
    const lang = useAppSelector(currentLang);
    const dispatch = useAppDispatch();

    return (
        <GridLayout
            className="layout"
            breakpoints={{ lg: 1300, md: 1000, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 12, sm: 6, xs: 6, xxs: 6 }}
            rowHeight={104}>

            <div
                key="1"
                data-grid={{ w: 6, h: 4, x: 0, y: 0, minW: 4, minH: 1, isDraggable: false, static: true }}
            >
                <AtomicCard
                    cardTitle={`Heatmap FX`}>
                        <ThemeHeatMap>
                            <FinancialHeatMap
                                key={`main-FinancialHeatMap-${19}`}
                                instance={'19'}
                                language={lang.code}
                            />
                        </ThemeHeatMap>
                </AtomicCard>
            </div>

            <div
                key="2"
                data-grid={{ w: 6, h: 4, x: 6, y: 0, minW: 4, minH: 1, isDraggable: false, static: true }}
            >
                <AtomicCard
                    cardTitle={`Heatmap índices`}>
                        <ThemeHeatMap>
                            <FinancialHeatMap
                                key={`main-FinancialHeatMap-${20}`}
                                instance={'20'}
                                language={lang.code}
                            />
                        </ThemeHeatMap>
                </AtomicCard>
            </div>

            <div
                key="3"
                data-grid={{ w: 6, h: 4, x: 0, y:4, minW: 4, minH: 1, isDraggable: false, static: true }}
            >
                <AtomicCard
                    cardTitle={`Heatmap Local`}>
                        <ThemeHeatMap>
                            <FinancialHeatMap
                                key={`main-FinancialHeatMap-${22}`}
                                instance={'4'}
                                language={lang.code}
                            />
                        </ThemeHeatMap>
                </AtomicCard>
            </div>

            <div
                key="4"
                data-grid={{ w: 6, h: 4, x: 6, y: 4, isDraggable: false}}
            >
                <AtomicCard
                    cardTitle={`Heatmap SIC`}>
                        <ThemeHeatMap>
                            <FinancialHeatMap
                                key={`main-FinancialHeatMap-${18}`}
                                instance={'18'}
                                language={lang.code}
                            />
                        </ThemeHeatMap>
                </AtomicCard>
            </div>

            <div
                key="6"
                data-grid={{ w: 6, h: 4, x: 0, y: 8, isDraggable: false }}
            >
                <AtomicCard
                    cardTitle={`G&L BMV`}>
                    <ThemeTable>
                        <FinancialWinnersLosers
                            key={`main-FinancialWinnersLosers-${5}`}
                            instance={'5'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>

            <div
                key="7"
                data-grid={{ w: 6, h: 4, x: 6, y: 8, isDraggable: false }}
            >
                <AtomicCard
                    cardTitle={`G&L SIC`}>
                    <ThemeTable>
                        <FinancialWinnersLosers
                            key={`main-FinancialWinnersLosers-${21}`}
                            instance={'21'}
                            language={lang.code}
                        />
                    </ThemeTable>
                </AtomicCard>
            </div>

            <div
                key="8"
                data-grid={{ w: 12, h: 4, x: 0, y: 12, isDraggable: false }}
            >
                <AtomicCard
                    cardTitle={`Gráfica simple`}>
                    <ThemeChart>
                        <FinancialSimpleChart
                            key={`main-FinancialSimpleChart-${6}`}
                            instance={'6'}
                            language={lang.code}
                            singleChart={{}}
                            onChangeInstrument={payload => dispatch(setSelectedEquity(payload))}
                        />
                    </ThemeChart>
                </AtomicCard>
            </div>
        </GridLayout>
    )
    
}
import React, { useState, useEffect } from "react"
import { HeaderStyle } from "./style"
import { useAuthContext } from "@asgardeo/auth-react";

import {
    AtomicButtonIcon,
    AtomicButton,
    AtomicText,
    AtomicMenu,
    AtomicMenuItem,
    AtomicDialog,
    AtomicTooltip,
    MessageAlert
} from "../../atom"
import ConfigOptions from './content/configOptions'
import InfoUser from './content/infoUser'
import { Menu, Repeat, ArrowDownCircle, ArrowUpCircle, Search, Maximize, Bell, ChevronDown, Settings, User } from 'react-feather';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';
import FormTransfer from '../formTransfer'
import FormWithdraw from '../formWithdraw'
import FormDeposit from '../formDeposit'
import { FinancialSearch, useRealtime } from "fs-widgets-core";
import { toggleShowMenu } from "../../features/settings/settingsSlice";
//@ts-ignore
import SockJsClient from 'react-stomp';
import { useLocation, useNavigate } from "react-router";
import { selectContracts, selectCurrentContract, selectStockPorfolio, selectCurrentUser, getContracts, getStockPortfolio, makeTransfer, ServiceStatus, setContract, setTransfer, selectTransferState, selectFundsState, selectEquitiesState, getFundsOrders, getEquitiesOrders, getListFunds, getPositions, selectBoletState, setBoletTab } from "../../trader/traderSlice";
import ContractModel from "../../trader/interface/contract.model";
import { defaultTransferState } from "../../trader/interface/transfer.model";
import Logo from "../../assets/images/logo-inverse.svg"
import moment from "moment";
import { currentLang } from "../../lang/langSlice";
import { getBusinessDays, getInfoTermsService, updateTermsService } from "../../trader/traderAPI";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {signOut} = useAuthContext()
    const { initSettings, settings, boletCount, openBolet } = useRealtime();
    const dispatch = useAppDispatch();
    const theme = useAppSelector(currentTheme);
    const [openSettings, setOpenSettings] = React.useState(false);
    const [openInfoUser, setOpenInfoUser] = React.useState(false);
    const [openDialogWithdraw, setOpenDialogWithdraw] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const bolet = useAppSelector(selectBoletState);
    const lang = useAppSelector(currentLang);
    // bolet props
    const [dateOptions, setDateOptions] = useState<any>({
        minDate: new Date(),
        maxDate: new Date(),
        excludeDates: []
    });
    const {
        listFunds,
        selectedFund,
    } = useAppSelector(selectFundsState);
    const {
        selectedEquity,
    } = useAppSelector(selectEquitiesState);
    // end bolet props
    const [alert, setAlert] = useState(
        {
            open: false,
            title: '',
            message: ''
        }
    )
    const open = Boolean(anchorEl);
    const contracts = useAppSelector(selectContracts);
    const selectedContract = useAppSelector(selectCurrentContract);
    const user = useAppSelector(selectCurrentUser);
    const portfolio = useAppSelector(selectStockPorfolio);
    const {
        entity: formTransfer,
        status: transferStatus
    } = useAppSelector(selectTransferState);
    const [openSearch, setOpenSearch] = useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleOnChangeContract = (c: ContractModel | null) => () => {
        setAnchorEl(null);
        if (c) {
            dispatch(setContract(c));
        }
    };
    const handleClickOpenDialogWithdraw = () => {
        dispatch(setTransfer(defaultTransferState))
        setOpenDialogWithdraw(true);
    };
    const handleCloseDialogWithdraw = () => {
        setOpenDialogWithdraw(false);
    };
    const handleAcceptDialogWithdraw = () => {
        if (!isTransferDisabled()) {
            dispatch(makeTransfer(formTransfer));
        }
    }

    ////

    const handleClickSettings = () => {
        setOpenSettings(!openSettings);
    };

    const handleClickUser = () => {
        setOpenInfoUser(!openInfoUser);
    };

    const handleOnClickMenu = () => {
        dispatch(toggleShowMenu())
    }

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }

    const menuIconBtn = [
        {
            tooltip: "Buscador",
            class: "header-color-svg",
            action: () => { setOpenSearch(!openSearch) },
            icon: <Search />,
        },
        {
            tooltip: "Full screen",
            class: "header-color-svg",
            action: () => toggleFullScreen(),
            icon: <Maximize />,
        },
        {
            tooltip: "Alarmas",
            class: "header-color-svg",
            action: () => navigate('/notifications'),
            icon: <Bell />,
        },
        {
            tooltip: "Tema",
            class: "header-color-svg",
            action: handleClickSettings,
            icon: <Settings />,
        },
        {
            tooltip: "Usuario",
            class: "header-color-svg show-user-btn",
            action: handleClickUser,
            icon: <User />,
        },
    ]

    useEffect(() => {
        if (user?.alias) {
            dispatch(getContracts());
        }
    }, [user?.alias]);

    useEffect(() => {
        if (contracts.length > 0) {
            const currentContractIndex = contracts.findIndex(c => c.id === selectedContract?.id);
            const defaultContract = contracts.find(c => c.default === '1');
            if (currentContractIndex === -1) {
                if (defaultContract) {
                    dispatch(setContract(defaultContract));
                } else {
                    dispatch(setContract(contracts[0]));
                }
            } else {
                dispatch(setContract(contracts[currentContractIndex]));
            }
        }
    }, [contracts]);
    
    useEffect(() => {
        if (user?.alias && selectedContract?.id) {
            dispatch(getStockPortfolio());
        }
    }, [selectedContract?.id, user?.alias]);

    useEffect(() => {
        if(!openBolet && bolet.tabSelected) {
            dispatch(setBoletTab(undefined))
        }
    }, [openBolet]);

    const isTransferDisabled = () => {
        return !formTransfer.cveDivisa
            || !formTransfer.fOperacion
            || !formTransfer.idContrato
            || !formTransfer.idPersonaRegistra
            || !formTransfer.impMovimiento
            || !formTransfer.vostroNumCtaClabe
            || !formTransfer.vostroNumCtaCliente
    }

    const parseNotifications = (message: any) => {
        if (message) {
            try {
                const parse = JSON.parse(message);
                setAlert(
                    {
                        open: true,
                        title: parse.symbol,
                        message: parse.message
                    }
                )
            } catch (error) {

            }
        }
    }

    let timeout: NodeJS.Timeout | null = null;

    const expireSession = () => {
        signOut();
    };

    const restartAutoReset = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            expireSession();
        }, (1000 * 60) * 15 );
    };

    const onMouseMove = () => {
        restartAutoReset();
    };

    useEffect(
        () => {
            try {
                if (location && !String(location.pathname).includes('home')) {
                    initSettings({ ...settings, open: false, changed: false })
                }
            } catch (error) {

            }

            // initiate timeout
            restartAutoReset();

            // listen for mouse events
            window.addEventListener('mousemove', () => onMouseMove());

            // cleanup
            return () => {
                if (timeout) {
                    clearTimeout(timeout);
                    window.removeEventListener('mousemove', () => onMouseMove());
                }
            };
            
        }, [location.pathname]
    )

    //financial bolet actions
    useEffect(() => {
        (async () => {
            const dates = await getBusinessDays();
            if (dates.length === 0) {
                return;
            }
            const dateSet = new Set(dates);
            const minDate = moment(dates[0], "YYYY-MM-DD").toDate();
            const maxDate = moment(dates[dates.length - 1], "YYYY-MM-DD").toDate();
            const excludeDates = new Array<Date>();
            let date = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate() + 1);
            while (date < maxDate) {
                if (!dateSet.has(moment(date).format('YYYY-MM-DD'))) {
                    excludeDates.push(date);
                }
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
            }
            setDateOptions({
                minDate,
                maxDate,
                excludeDates
            });
        })();
    }, []);
    const handleGetInfoTerms = (alias: string) => {
        (async () => {
            const base64 = await getInfoTermsService(alias);
            if (!base64) {
                return;
            }
            const byteCharacters = atob(base64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const file = new Blob([byteArray], { type: 'application/pdf;base64' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

        })();
    }
    const handleUpdateContractTerms = (alias: string, contractId: string) => {
        updateTermsService(alias, contractId);
    }
    const onChangeContract = (payload: ContractModel) => {
        dispatch(setContract(payload));
    }
    const onCreateEquityOrder = () => {
        if (user?.alias && selectedContract?.id) {
            dispatch(getContracts());
            updateData();
        }
    }
    const onCreateFundOrder = () => {
        if (user?.alias && selectedContract?.id) {
            dispatch(getContracts());
            updateData();
        }
    }

    const getDataEquitiesOrders = () => {
        const alias = user.alias;
        const contractId = selectedContract?.id;
        if (alias && contractId) {
            dispatch(getFundsOrders({ alias, contractId }))
        }
    }
    const getDataFundsOrders = () => {
        const alias = user.alias;
        const contractId = selectedContract?.id;
        if (alias && contractId) {
            dispatch(getEquitiesOrders({ alias, contractId }))
        }
    }
    const updateData = () => {
        dispatch(getStockPortfolio());
        dispatch(getListFunds());
        dispatch(getPositions());
        getDataEquitiesOrders();
        getDataFundsOrders();
    }
    const boletProps = {
        url:"",
        instance: `financial-bolet-${(boletCount + 1)}`,
        language: lang.code,
        alias: user.alias,
        contracts: contracts,
        currentContract: selectedContract,
        dateOptions: dateOptions,
        funds: listFunds,
        selectedEquity: selectedEquity,
        selectedFund:selectedFund,
        onGetInfoTerms: handleGetInfoTerms,
        onUpdateContractTerms: handleUpdateContractTerms,
        onChangeContract: onChangeContract,
        onCreateEquityOrder: onCreateEquityOrder,
        onCreateFundOrder: onCreateFundOrder,
        tabSelected:bolet.tabSelected,
    };
    return (
        <>
            <SockJsClient
                key={'realtime-broker'}
                url={`${process.env.REACT_APP_NOTIFICATIONS_URL}`}
                onMessage={(message: any) => parseNotifications(message)}
                topics={[`/notification/${user?.alias}`]}
            >
            </SockJsClient>

            <HeaderStyle theme={theme}>
                <div className="content-left">
                    <div className="primary-content">
                        <AtomicButtonIcon
                            variant="text"
                            className="header-color-svg menu"
                            onClick={handleOnClickMenu}
                        >
                            <Menu />
                        </AtomicButtonIcon>
                        <img
                            className="logo"
                            src={Logo}
                            alt={"logo"}
                            loading="lazy"
                        />
                    </div>
                    <div className="secondary-content">
                        <div className="info pointer" onClick={handleClick}>
                            <AtomicText variant="body2" size="11" textnowrap="true">Contrato</AtomicText>
                            <AtomicText variant="body1" size="13" fontFamily="secondary" textnowrap="true" bold>{selectedContract?.id}<ChevronDown size="12px" /> </AtomicText>
                        </div>
                        <AtomicMenu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleOnChangeContract(null)}
                        >
                            {contracts.map(c => (
                                <AtomicMenuItem onClick={handleOnChangeContract(c)} key={`Contract-${c.id}`} >{c.id}</AtomicMenuItem>
                            ))}
                        </AtomicMenu>
                        <div className="info">
                            <AtomicText variant="body2" size="11" textnowrap="true">Valor cartera</AtomicText>
                            <AtomicText variant="body1" size="13" fontFamily="secondary" textnowrap="true" bold>{(portfolio?.value)}</AtomicText>
                        </div>
                        <div className="info">
                            <AtomicText variant="body2" size="11" textnowrap="true">Efectivo disponible</AtomicText>
                            <AtomicText variant="body1" size="13" fontFamily="secondary" textnowrap="true" bold>{(portfolio?.clasification?.cash)}</AtomicText>
                        </div>
                        <div className="info">
                            <AtomicText variant="body2" size="11" textnowrap="true">Inversionista</AtomicText>
                            <AtomicText variant="body1" size="13" textnowrap="true" bold>{selectedContract?.name}</AtomicText>
                        </div>
                    </div>
                </div>
                <div className="content-right">
                    {/* It is being hidden at the client's request, as they do not yet have the production API ready for that functionality. */}
                    {/* <AtomicButton
                        onClick={handleClickOpenDialogWithdraw}
                        text="Transferir"
                        variant="contained"
                        color="secondary" 
                        size="large"
                        startIcon={<Repeat />}
                    />
                    <AtomicDialog
                        maxWidth="xs"
                        open={openDialogWithdraw}
                        onClose={transferStatus !== ServiceStatus.LOADING ? handleCloseDialogWithdraw : () => { }}
                        titleDialog="Transferir"
                        saveText="Continuar"
                        cancelText="Cancelar"
                        actionCancel={handleCloseDialogWithdraw}
                        actionSave={handleAcceptDialogWithdraw}
                        saveDisabled={isTransferDisabled() || transferStatus === ServiceStatus.LOADING}
                        cancelDisabled={transferStatus === ServiceStatus.LOADING}
                        btnFull
                    >
                        <FormTransfer
                            onClose={handleCloseDialogWithdraw}
                        />
                    </AtomicDialog> */}
                    {menuIconBtn.map((item: any, index) => (
                        <AtomicTooltip title={item.tooltip}>
                            <AtomicButtonIcon
                                className={item.class}
                                onClick={item.action}
                            >
                                {item.icon}
                            </AtomicButtonIcon>
                        </AtomicTooltip>
                    ))}
                </div>
            </HeaderStyle>
            <ConfigOptions open={openSettings} onClose={handleClickSettings} />
            <InfoUser
                open={openInfoUser}
                onClose={handleClickUser}
                //@ts-ignore
                valuePortfolio={(portfolio?.value)}
                //@ts-ignore
                cash={(portfolio?.clasification?.cash)}
                name={selectedContract?.name}
            />
            <FinancialSearch
                language={'es_MX'}
                open={openSearch}
                setOpenSearch={setOpenSearch}
                watchlistInstance={'8'}
                boletProps={boletProps}
            />
        </>
    )

}

export default Header;


import styled from "styled-components";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { FontSize } from '../../utils/Constants/FontSize';

const CardStyle = styled(Card) <any>`
  /* div[class*="backdrop"]{
    background-color: rgba(0,0,0,0.0) !important;
  }
  div[class*="paper"]{
      background-color: #001865!important;
      color: white;
  } */
  box-shadow: none !important;
  background-color: ${props => props.theme.palette.card.bg} !important;
  color: ${props => props.theme.palette.card.text} !important;
  height: 100%;
`;
const CardContentStyle = styled(CardContent) <any>`
  padding: ${props => props.noScroll ? '10px 0 10px 10px' : '10px'}!important;
  height: calc(100% - 39px);
  box-sizing: border-box;
  overflow: ${props => props.noScroll ? 'hidden' : 'auto'};
`;
const CardHeaderStyle = styled.div <any>`
  background-color: ${props => props.theme.palette.card.header};
  color: ${props => props.theme.palette.card.headerText};
  height: 39px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p{
    ${FontSize[12]};
    font-weight: 700;
  }
  button{
    svg{
      color: ${props => props.theme.palette.card.headerText};
    }
  }
  &.hoverable{
    opacity: 0;
    background-color: transparent !important;
    padding: 10px;
    position: absolute;
    gap: 10px;
    top: 0;
    right: 0;
    transition: .2s ease-in;
    &:hover {
      opacity: 1;
      background-color: rgba(0,0,0,0.5) !important;
      transition: .2s ease-in;
    }
  }
`;

export { CardStyle, CardContentStyle, CardHeaderStyle };
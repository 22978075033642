import React, { useEffect } from "react"
import { FinancialBolet, FinancialMonitor, FinancialOrders, FinancialPositions, FinancialSimpleChart, FinancialTransactionHistory } from "fs-widgets-core"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import GridLayout from "../../atom/gridLayout"
import { currentLang } from "../../lang/langSlice"
import { getEquitiesOrders, getFundsOrders, getListFunds, getPositions, InstrumentKey, selectCurrentContract, selectCurrentUser, selectEquitiesState, selectFundsState, selectPositionsState, setSelectedEquity, setSelectedFund } from "../../trader/traderSlice"
import { ThemeBolet, ThemeChart, ThemeFunds, ThemeTable, ThemeTransactionHistory } from "../../widgets"
import { AtomicCard } from "../../atom"
import { currentTheme } from "../../theme/hooks/themeSlice"
export default function TraderPage() {
  const dispatch = useAppDispatch();
  const theme = useAppSelector(currentTheme);
  const lang = useAppSelector(currentLang);
  const user = useAppSelector(selectCurrentUser);
  const contract = useAppSelector(selectCurrentContract);
  const fundsState = useAppSelector(selectFundsState);
  const equitiesState = useAppSelector(selectEquitiesState);

  //Financial orders
  const getDataEquitiesOrders = () => {
    const alias = user.alias;
    const contractId = contract?.id;
    if (alias && contractId) {
      dispatch(getFundsOrders({ alias, contractId }))
    }
  }
  const getDataFundsOrders = () => {
    const alias = user.alias;
    const contractId = contract?.id;
    if (alias && contractId) {
      dispatch(getEquitiesOrders({ alias, contractId }))
    }
  }
  const handleSelectInstrument = (payload: { type: 'fund' | 'equity', issuer: string; serie: string }) => {
    const { issuer, serie } = payload;
    switch (payload.type) {
      case 'equity':
        dispatch(setSelectedEquity({ issuer, serie }))
        break;
      case 'fund':
        dispatch(setSelectedFund({ issuer, serie }));
        break;
    }
  }

  //// FINANCIAL POSITIONS
  const {
    status: statusPositions,
    data: positions
  } = useAppSelector(selectPositionsState);

  return <>
    <GridLayout
      className="layout"
      breakpoints={{ lg: 1300, md: 1000, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 12, sm: 6, xs: 6, xxs: 6  }}
      rowHeight={51}>
      <div
        key="1"
        data-grid={{ w: 12, h: 10, x: 0, y: 0, isDraggable: false }}
      >
        <AtomicCard
          configWidget
          cardTitle={`Boleta`}
        >
          <ThemeBolet
            instance={'theme-bolet'}
          />

        </AtomicCard>
      </div>

      <div
        key="4"
        data-grid={{ w: 12, h: 7, x: 0, y: 24, isDraggable: false }}
      >
        <AtomicCard
          configWidget
          cardTitle={`Órdenes`}
        >
          <ThemeTable fullHeight>
            <FinancialOrders
              headerFixed={true}
              instance="financial-orders"
              url={`${process.env.REACT_APP_TRADER_SERVICES}/orders`}
              language={lang.code}
              contract={{
                alias: user?.alias,
                contractId: contract?.id
              }}
              getDataEquitiesOrders={getDataEquitiesOrders}
              getDataFundsOrders={getDataFundsOrders}
              equitiesOrders={equitiesState.orders}
              fundsOrders={fundsState.orders}
              setSelectedInstrument={handleSelectInstrument}
              showCancelAll={equitiesState.orders.showCancel}
            />
          </ThemeTable>
        </AtomicCard>
      </div>
      <div
        key="2"
        data-grid={{ w: 12, h: 7, x: 0, y: 10, isDraggable: false }}
      >
        <AtomicCard
          configWidget
          cardTitle={`Tabla Fondos`}
        >
          <ThemeFunds
            instance="financial-bolet"
            language={lang.code}
          />
        </AtomicCard>
      </div>
      <div
        key="3"
        data-grid={{ w: 12, h: 7, x: 0, y: 17, isDraggable: false }}
      >
        <AtomicCard
          configWidget
          cardTitle={`Posiciones`}
        >
          <ThemeTable fullHeight>
            <FinancialPositions
              headerFixed={true}
              instance='financial-positions'
              language={lang.code}
              url={`${process.env.REACT_APP_TRADER_SERVICES}`}
              positions={positions}
              status={statusPositions}
              handleOnClickRow={handleSelectInstrument}
            />
          </ThemeTable>
        </AtomicCard>
      </div>
      <div
          key="8"
          data-grid={{ w: 6, h: 7, x: 0, y: 24, isDraggable: false }}
      >
          <AtomicCard
              cardTitle={`Gráfica simple`}>
              <ThemeChart>
                  <FinancialSimpleChart
                      key={`main-FinancialSimpleChart-${6}`}
                      instance={'6'}
                      language={lang.code}
                      singleChart={{}}
                      onChangeInstrument={payload => dispatch(setSelectedEquity(payload))}
                  />
              </ThemeChart>
          </AtomicCard>
      </div>
      <div
          key="11"
          data-grid={{ w: 6, h: 7, x: 6, y: 24, isDraggable: false }}
      >
          <AtomicCard
              cardTitle={`Monitor`}>
              <ThemeTable fullHeight>
                  <FinancialMonitor
                      headerFixed={true}
                      key={`main-FinancialMonitor-${21}`}
                      instance={'3'}
                      language={lang.code}
                      handleOnClickRow={payload => dispatch(setSelectedEquity(payload))}
                  />
              </ThemeTable>
          </AtomicCard>
      </div>
      <div
          key="13"
          data-grid={{ w: 12, h: 7, x: 0, y: 31, isDraggable: false }}
      >
          <AtomicCard cardTitle={`Historial de transacciones`}>
              <ThemeTransactionHistory
                key={'theme-transaction-history'}
                instance={'financial-transaction-history'}
              />
          </AtomicCard>
      </div> 
    </GridLayout>

  </>
}
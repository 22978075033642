import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import themeSlice from '../theme/hooks/themeSlice';
import langSlice from '../lang/langSlice';
import traderSlice from '../trader/traderSlice';
import settingsSlice from '../features/settings/settingsSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: counterReducer,
    theme: themeSlice,
    lang: langSlice,
    trader: traderSlice,
    settings: settingsSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

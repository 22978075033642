import styled from "styled-components";
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FontSize } from '../../utils/Constants/FontSize';

const ItemMenu = styled(MenuItem) <any>`
    ${FontSize[11]};
    color: white !important;
    background: #001865 !important;
    min-height: auto !important;
    &:hover{
        background-color: rgba(0,0,0,0.15) !important;
    }
`;
const ItemText = styled(ListItemText) <any>`
    span{
        ${FontSize[11]};
        line-height: 1 !important;
    }
`;
const ItemIcon = styled(ListItemIcon) <any>`
    min-width: 30px !important;
    svg{
        width: 20px;
        height: 20px;
        fill: white !important;
    }
`;

export { ItemMenu, ItemText, ItemIcon };
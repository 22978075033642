import { useAuthContext } from "@asgardeo/auth-react";
import { File, Book, Briefcase, Home, Power, Table, HelpCircle } from "react-feather";
import { useNavigate } from "react-router";
import { AtomicIconAnalisys } from "../../atom";
import { PropsMenuitem } from "./interface";

export function useElements() {
  const navigate = useNavigate();
  const {signOut} = useAuthContext()
  return [
    { className: "", icon: <Home />, name: "Resumen", onClick: () => navigate('/home')},
    { icon: <Table />, name: "Watchlist", onClick: () => navigate('/watchlists') },
    { icon: <Briefcase />, name: "Portafolio", onClick: () => navigate('/portfolio')},
    { icon: <Book />, name: "Historial alertas" , onClick: () => navigate('/notifications-history')},
    { icon: <AtomicIconAnalisys />, name: "Análisis técnico" , onClick: () => navigate('/technical-analysis')},
    { icon: <File />, name: "Add in Xls" , onClick: () => navigate('/add-in-xls')},
    { icon: <HelpCircle />, name: "Ayuda" , onClick: () => navigate('/help')},
    { icon: <Power />, name: "Cerrar sesión" , onClick: () => {
      signOut();
    }},
  ] as PropsMenuitem[];
}

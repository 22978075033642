export default {};
const normalize = (size: number) => {
   let pixelRatio = 1;
   if (typeof window !== "undefined") {
     pixelRatio = window.devicePixelRatio;
   }
  if (pixelRatio >= 2) {
    return Math.round(size * pixelRatio) - 3;
  }
  return Math.round(size * pixelRatio);
};

const TRADER_REFRESH_INTERVAL_MS = 60*1000;

export { normalize, TRADER_REFRESH_INTERVAL_MS };
import styled from "styled-components";

const ContentExcel = styled.div`
    .wrapper-excel{
        color: ${props => props.theme.palette.layout.text};
        .info{
            .color-path{
                .stroke{
                    stroke: ${props => props.theme.palette.colors.excel};
                }
                .fill{
                    fill: ${props => props.theme.palette.colors.excel};
                }
            }
            .note{
                background-color: ${props => props.theme.palette.colors.secondary} !important;
                color: white;
            }
        }
        .steps{
            background-color: ${props => props.theme.palette.table.row};
        }
        .wrapper-instalation{
            background-color: ${props => props.theme.palette.table.row};
            .accordion{
                color: ${props => props.theme.palette.layout.text} !important;
                .circle{
                    color: #FFFFFF !important;
                    font-family: Roboto;
                }
                div[class*="expandIconWrapper"]{
                    color: ${props => props.theme.palette.layout.text} !important;
                }
                div[class*="MuiAccordionDetails"]{
                    img{
                        border-radius: 8px !important;
                    }
                }
            }
        }
    }
`;

export { ContentExcel };
import styled from 'styled-components';
import { FontSize } from '../../../../utils/Constants/FontSize';

const Wrapper = styled.div <any>`
    box-sizing: border-box;
    width: 312px;
    height: auto;
    transition: .4s ease-in-out;
    position: fixed;
    top: 60px;
    z-index: 1201;
    right: -320px;
    padding: 10px;
    background-color: #001865;
    color: rgba(255,255,255,0.8);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14), 0px 3px 14px 2px rgb(0, 0, 0, .12) !important;
    ${props => props.open && 'right: 0;'}
    .header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .content-checks{
        display: flex;
        gap: 10px;
        padding-top: 10px;
        > label{
            flex: 1;
        }
    }
    
    .sg-Radio, .sg-Check {
        display: inline-block;
        position:relative;
    }
    
    .sg-Radio-input,
    .sg-Check-input {
    position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0 0 0 0);
        border: 0;
    }

    .sg-Radio-btn {
        .sg-Radio-input {
            & ~ .sg-Radio-text {
                box-sizing: border-box;
                border: 1px solid;
                border-color: #06152c;
                display: flex;
                flex: 1;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 10px;
                min-height: 70px;
                background-color: #06152c;
                border-radius: 10px;
                transition: all 0.2s;
                padding: .5rem;
                ${FontSize[12]};
                font-family: Raleway;
                user-select: none;
                &.dark{
                    background-color: #06152c;
                    border-color: #06152c;
                }
                &.ligth{
                    background-color: #FFFFFF;
                    border-color: #FFFFFF;
                    color: #64748B;
                }
            }
            &:not(:disabled) {
                &:not(:checked) {
                    & ~ .sg-Radio-text {
                        .sg-Radio-icon {
                            display: none;
                        }
                    }
                    & ~ .sg-Radio-text:hover {
                        /* background: #0098DD;
                        border-color: blue; */
                        opacity: 0.75;
                    }
                }
                &:checked {
                    & ~ .sg-Radio-text {
                        /* background: #2260ff; */
                        border-color: #2260ff;
		                box-shadow: 0 5px 8px rgba(34,96,255, 0.25);
                    }
                    &:hover ~ .sg-Radio-text {
                        color: gray;
                    }
                }
            }
        }
    }
`;

export { Wrapper };
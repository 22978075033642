import React, { type FC } from 'react'
import {ContentDash} from "./style"
import PropTypes from "./interface"
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';

const ThemeDash: FC<PropTypes> = (props) => {
    const {children} = props
    const theme = useAppSelector(currentTheme);
    return(
        <ContentDash theme={theme}>
            {children}
        </ContentDash>
    )

}

export default ThemeDash;


import React,{ type FC } from 'react'
import {Toltip} from './style';
import PropsProperties from './interface';

const AtomicTooltip: FC<PropsProperties> = (props) => {

    const {
        title,
        children
    } = props

    return (
        <Toltip title={title}>
            <span>
                {children}
            </span>
        </Toltip>
    )
}

export default AtomicTooltip;
import React, { useEffect, useState } from 'react';
import { FinancialDashboard, FinancialHeatMap, FinancialMonitor, FinancialNotice, FinancialSimpleChart, FinancialTable, FinancialTicker, FinancialWinnersLosers, useRealtime } from 'fs-widgets-core';
import {
    AtomicCard,
    GridLayout
} from "../../atom"
import { getBaseURL } from '../../utils';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ThemeTicker, ThemeTable, ThemeChart, ThemeNotice, ThemeBolet, ThemeDash, ThemeDrawer, ThemeFunds, ThemeHeatMap, ThemeTwitter } from '../../widgets'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { currentLang } from '../../lang/langSlice';
import { currentTheme } from '../../theme/hooks/themeSlice';
import { getContracts, getEquitiesOrders, getFundsOrders, getListFunds, getPositions, getStockPortfolio, selectContracts, selectCurrentContract, selectCurrentUser, selectEquitiesState, selectFundsState, selectPositionsState, selectTransferState, setBoletTab, setContract, setSelectedEquity, setSelectedFund } from '../../trader/traderSlice';
import { getBusinessDays, getInfoTermsService, getTransactionsService, updateTermsService } from '../../trader/traderAPI';
import moment from 'moment';
import ContractModel from '../../trader/interface/contract.model';
import { ThemeProvider } from 'styled-components';
import { createTheme } from '@mui/material';

interface DateOptions {
    minDate: Date;
    maxDate: Date;
    excludeDates: Date[];
}

export function Home() {
    const {setOpenBolet} = useRealtime();
    const theme = useAppSelector(currentTheme);
    const lang = useAppSelector(currentLang);
    const user = useAppSelector(selectCurrentUser);
    const contracts = useAppSelector(selectContracts);
    const contract = useAppSelector(selectCurrentContract);
    const transferState = useAppSelector(selectTransferState);
    const [typeSelect, setTypeSelect] = React.useState('CAPITALES');
    const [typeTransactionDate, setTypeTransactionDate] = React.useState('OPERACION');
    const [startDate, setStartDate] = React.useState(new Date().toISOString());
    const [endDate, setEndDate] = React.useState(new Date().toISOString());
    const [isLoading, setIsLoading] = useState(false);
    const [transactionHistory, setTransactionHistory] = useState<any>();
    const [data, setData] = useState<any[]>([]);
    //// FINANCIAL POSITIONS
    const {
        status: statusPositions,
        data: positions
    } = useAppSelector(selectPositionsState);

    const {
        listFunds,
        selectedFund,
        status,
        orders: orderFunds
    } = useAppSelector(selectFundsState);

    const {
        selectedEquity,
        orders
    } = useAppSelector(selectEquitiesState);

    const [dateOptions, setDateOptions] = useState<DateOptions>({
        minDate: new Date(),
        maxDate: new Date(),
        excludeDates: []
    });

    useEffect(() => {
        setData(listFunds.map((item: any, index: number) => ({
            issuer: item.issuer,
            serie: item.serie,
            yieldMonth: item.yieldMonthly,
            yield12m: item.yield12m,
            yieldYearly: item.yieldYear,
            type: item.type === '51' ? 'Renta fija' : item.type === '52' ? 'Renta variable' : '',
            titles: item.titlesAvailable,
            price: item.price,
            purchaseDate: item.purchaseLiquidDate,
            saleDate: item.saleLiquidDate,
            id: index
        })))
    }, [listFunds]);

    const dispatch = useAppDispatch();
    const handleGetInfoTerms = (alias: string) => {
        (async () => {
            const base64 = await getInfoTermsService(alias);
            if (!base64) {
                return;
            }
            const byteCharacters = atob(base64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const file = new Blob([byteArray], { type: 'application/pdf;base64' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

        })();
    }
    const handleUpdateContractTerms = (alias: string, contractId: string) => {
        updateTermsService(alias, contractId);
    }
    useEffect(() => {
        (async () => {
            const dates = await getBusinessDays();
            if (dates.length === 0) {
                return;
            }
            const dateSet = new Set(dates);
            const minDate = moment(dates[0], "YYYY-MM-DD").toDate();
            const maxDate = moment(dates[dates.length - 1], "YYYY-MM-DD").toDate();
            const excludeDates = new Array<Date>();
            let date = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate() + 1);
            while (date < maxDate) {
                if (!dateSet.has(moment(date).format('YYYY-MM-DD'))) {
                    excludeDates.push(date);
                }
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
            }
            setDateOptions({
                minDate,
                maxDate,
                excludeDates
            });
        })();
    }, []);
    const onChangeContract = (payload: ContractModel) => {
        dispatch(setContract(payload));
    }
    const onCreateEquityOrder = () => {
        if (user?.alias && contract?.id) {
            dispatch(getContracts());
            updateData();
        }
    }
    const onCreateFundOrder = () => {
        if (user?.alias && contract?.id) {
            dispatch(getContracts());
            updateData();
        }
    }

    const handleOnClickRow = (payload: any) => {
        const { issuer, serie } = payload;
        if (issuer && serie) {
            dispatch(setSelectedFund({ issuer, serie }));
            setOpenBolet(true);
            dispatch(setBoletTab('funds'));
        }
    }

    const getDataEquitiesOrders = () => {
        const alias = user.alias;
        const contractId = contract?.id;
        if (alias && contractId) {
            dispatch(getFundsOrders({ alias, contractId }))
        }
    }
    const getDataFundsOrders = () => {
        const alias = user.alias;
        const contractId = contract?.id;
        if (alias && contractId) {
            dispatch(getEquitiesOrders({ alias, contractId }))
        }
    }

    const handleSelectInstrument = (payload: { type: 'fund' | 'equity', issuer: string; serie: string }) => {
        const { issuer, serie } = payload;
        switch (payload.type) {
            case 'equity':
                dispatch(setSelectedEquity({ issuer, serie }))
                break;
            case 'fund':
                dispatch(setSelectedFund({ issuer, serie }));
                break;
        }
    }

    const getDataTransactions = async () => {
        const payload = {
            fechaInicio: moment(startDate).format('YYYY-MM-DD'),
            fechaFin: moment(endDate).format('YYYY-MM-DD'),
            cveMercado: typeSelect,
            tipoFecha: typeTransactionDate
        }
        const response: any = await getTransactionsService(user?.alias, contract?.id!, payload);

        const transactionHistoryBuild: any = {
            dataTable: []
        }

        if (response?.status === 200 && Array.isArray(response.data?.transacciones)) {
            (response.data.transacciones as any[]).map((data: any, idx: number) => {
                transactionHistoryBuild.dataTable.push({
                    field_settlementDate: data.fLiquidacion,
                    field_operationDate: data.fOperacion,
                    field_transactionDesc: data.descTransaccion,
                    field_issuerSerie: data.emisoraSerie,
                    field_company: data.empresa,
                    field_operationCve: data.cveOperacion,
                    field_marketCve: data.cveMercado,
                    field_grossTax: data.impBruto,
                    field_taxCommission: data.impComision,
                    field_taxInterest: data.impIntereses,
                    field_taxIsr: data.impIsr,
                    field_taxIva: data.impIva,
                    field_netTax: data.impNeto,
                    field_operationPrec: data.precOperacion,
                    field_titles: data.titulos,
                    field_currency: data.cveDivisa,
                    id: idx
                })
            })

        }
        setTransactionHistory(transactionHistoryBuild)
    }

    const headerProps = {
        typeSelect, setTypeSelect,
        startDate, setStartDate,
        endDate, setEndDate,
        typeTransactionDate, setTypeTransactionDate,
    }

    const updateData = () => {
        dispatch(getStockPortfolio());
        dispatch(getListFunds());
        dispatch(getPositions());
        getDataEquitiesOrders();
        getDataFundsOrders();
        getDataTransactions();
    }

    useEffect(() => {
        if (!user?.alias || !contract?.id) {
            return;
        }
        (async () => {
            setIsLoading(true);
            await getDataTransactions();
            setIsLoading(false);
        })();        
    }, [user, contract?.id, typeSelect, startDate, endDate, typeTransactionDate]);

    useEffect(() => {
        if(user.alias && contract?.id) {
            dispatch(getListFunds());
            dispatch(getPositions());
        }
    }, [user.alias, contract?.id]);

    useEffect(() => {
        if(transferState.response) {
          getDataTransactions();
        }
      }, [transferState.response]);

    return (
        <ThemeProvider theme={theme} >
            <ThemeBolet>
                <ThemeChart>
                    <ThemeDash>
                        <ThemeDrawer>
                            <ThemeFunds>
                                <ThemeHeatMap>
                                    <ThemeNotice>
                                        <ThemeTwitter>
                                            <ThemeTable>
                                                <ThemeTicker>
                                                    <FinancialDashboard
                                                        instance={'25'}
                                                        language={'es_MX'}
                                                        //params boleta start
                                                        alias={user?.alias}
                                                        // for agGrid tables
                                                        headerFixed={true}
                                                        onGetInfoTerms={handleGetInfoTerms}
                                                        onUpdateContractTerms={handleUpdateContractTerms}
                                                        dateOptions={dateOptions}
                                                        contracts={contracts}
                                                        currentContract={contract}
                                                        onChangeContract={onChangeContract}
                                                        funds={listFunds}
                                                        selectedEquity={selectedEquity}
                                                        selectedFund={selectedFund}
                                                        onCreateEquityOrder={onCreateEquityOrder}
                                                        onCreateFundOrder={onCreateFundOrder}
                                                        // params boleta end

                                                        // init tablas fondos 
                                                        //@ts-ignore
                                                        theme={createTheme(theme)}
                                                        data={data}
                                                        status={status}
                                                        handleOnClickRow={handleOnClickRow}
                                                        // end tablas fondos


                                                        //init orders params
                                                        contract={{
                                                            alias: user?.alias,
                                                            contractId: contract?.id
                                                        }}
                                                        getDataEquitiesOrders={getDataEquitiesOrders}
                                                        getDataFundsOrders={getDataFundsOrders}
                                                        equitiesOrders={orders}
                                                        fundsOrders={orderFunds}
                                                        setSelectedInstrument={handleSelectInstrument}
                                                        onUpdateOrders={updateData}
                                                        showCancelAll={orders.showCancel}
                                                        //end order params

                                                        transactionHistory={transactionHistory}
                                                        //@ts-ignore
                                                        theme={createTheme(theme)}
                                                        headerProps={headerProps}
                                                        isLoading={isLoading}
                                                        
                                                        //positions params
                                                        positions={positions}
                                                        //end positions params


                                                    />
                                                </ThemeTicker>
                                            </ThemeTable>
                                        </ThemeTwitter>
                                    </ThemeNotice>
                                </ThemeHeatMap>
                            </ThemeFunds>
                        </ThemeDrawer>
                    </ThemeDash>
                </ThemeChart>
            </ThemeBolet>
        </ThemeProvider>
    )

}
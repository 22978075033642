import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentTicker = styled.div`
    .animationArrow{
        color: ${props => props.theme.palette.layout.text};
        border-color: ${props => props.theme.palette.line.gray50};
        .nameTicker{
            ${FontSize[12]};
        }
        .lastPrice, .percentChange, .netChange{
            ${FontSize[11]};
        }
        &[color^=down] {
            .contentIcon{
                svg, .percentChange, .netChange{
                    color: ${props => props.theme.palette.colors.down};
                }
            }
        }
        &[color^=up] {
            .contentIcon{
                svg, .percentChange, .netChange{
                    color: ${props => props.theme.palette.colors.up};
                }
            }
        }
        .lastPrice{
            font-weight: 500 !important;
        }
    }
`;

export { ContentTicker };
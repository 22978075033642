import styled from 'styled-components';

const Menu = styled.main<any>`
    box-sizing: border-box;
    position: fixed;
    padding: 10px;
    padding-top: 70px;
    left: ${props => props.openMenu ? 0 : '-70px'} ;
    top: 0;
    bottom: 0;
    width: 70px;
    background-color: ${props => props.theme.palette.menu.bg};
    transition: all .2s;

    ul{
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
        li{
            button{
                box-sizing: border-box;
                user-select: none; 
                transition: .2s ease-in;
                cursor: pointer;
                border-radius: 4px;
                padding: 5px 2px;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                gap: 5px;
                border: none;
                color: white;
                background-color: transparent;
                svg {
                    width: 26px;
                    height: 26px;
                    color: ${props => props.theme.palette.menu.icon};
                }
                .colorFill{
                    fill: ${props => props.theme.palette.menu.icon};
                }
                span{
                    font-family: Raleway;
                    font-size: 9px;
                    color: ${props => props.theme.palette.menu.text};
                }
                &:hover svg{
                    opacity: .7;
                }
                &.active{
                    background-color: ${props => props.theme.palette.menu.bgActive};
                    svg{
                        color: ${props => props.theme.palette.menu.iconActive};
                    }
                    span{
                        color: ${props => props.theme.palette.menu.textActive};
                    }
                    .colorFill{
                        fill: ${props => props.theme.palette.menu.iconActive};
                    }
                }
            }
        }
    }
    
`;

export { Menu };
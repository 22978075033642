
import { useEffect, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FinancialNotifications, FinancialWatchlist } from 'fs-widgets-core';
import { ThemeTable } from '../../widgets'
import { useAppSelector } from '../../app/hooks';

import { selectCurrentContract, selectCurrentUser } from '../../trader/traderSlice';
import { Loader } from 'react-feather';
import { useNavigate } from 'react-router';

const NotificationPage = () => {
    
    const [contract, setContract] = useState<string>('');
    const currentContract = useAppSelector(selectCurrentContract);
    const user = useAppSelector(selectCurrentUser);
    const navigate = useNavigate()
    useEffect(
        () => {
            if(currentContract && currentContract.id){
                setContract(currentContract.id);
            }
        },[currentContract]
    );

    if(contract === ''){
        return <Loader />
    }

    return (
        <ThemeTable>
            <FinancialNotifications 
                instance={"26"}
                language={'es_MX'}
                alias={user ? user.alias : ''}
                contract={contract}
                onShowAll={() => navigate('/notifications-history')}
            />
        </ThemeTable>
    )

}

export default NotificationPage;

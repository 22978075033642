import Drawer from '@mui/material/Drawer';
import styled from 'styled-components';

const Wrapper = styled(Drawer) <any>`
    width: 240px;
    [class*="MuiDrawer-paper"]{
        width: 240px;
        background-color: ${props => props.theme.palette.layout.bg};
        color: ${props => props.theme.palette.layout.text};
        padding: 70px 10px 10px;
        box-sizing: border-box;
        font-family: Raleway;
    }
`;

export { Wrapper };
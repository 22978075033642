import styled from "styled-components";
import TextField from '@mui/material/TextField';
import { FontSize } from '../../utils/Constants/FontSize';

const Input = styled(TextField) <any>`
    
    box-sizing: border-box;
    font-family: Raleway;
    [class*="disabled"]{
        opacity: 0.75;
        -webkit-text-fill-color: white !important;
    }
    > label {
        ${FontSize[12]};
    }

    > div{
        &:before, &:after{
            display: none;
        }
    }

    label{
        font-family: Raleway !important;
        color: white !important;
        white-space: nowrap !important;
    }
    input, .MuiSelect-select{
        border: 1px solid;
        border-radius: 6px !important;
        border-color: #0D3570 !important;
        background: transparent;
        height: 28px !important;
        box-sizing: border-box;
        padding: 5px 10px 5px !important;
        color: white !important;
        ${FontSize[11]};
        line-height: 1.4 !important;
        &:focus {
            border-radius: 6px !important;
        }
    }

    .MuiSelect-icon{
        color: #FFFFFF !important;
    }
    
    &.searchInput{
        padding-right: 0px !important;
        input{
            padding: 7px 0 7px 10px !important;
            border: none !important;
        }
        [class*="notchedOutline"]{
            border-radius: 6px !important;
            border: 1px solid #0D3570 !important;
        }
        svg{
            width: 16px;
            height: 16px;
        }
    }
    
    textarea{
        border: 1px solid;
        border-radius: 6px !important;
        border-color: #0D3570 !important;
        background: transparent;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 5px 10px 5px !important;
        color: white !important;
        ${FontSize[11]};
    }

    /* input start adornment */

    [class*="adornedStart"] {
        border: 1px solid;
        border-color: #0D3570;
        border-radius: 6px;
        padding-left: 10px;
        > div {
            margin: 0;
            color: #FFFFFF;
            p{
                color: #FFFFFF !important;
                ${FontSize[11]};
            }
        }
        input {
            border: none !important;
            padding-left: 3px !important;
            height: 26px !important;
        }
    }
    
    /* --------------------- */
    /* input end adornment */

    [class*="adornedEnd"] {
        border: 1px solid;
        border-color: #0D3570;
        border-radius: 6px;
        padding-left: 10px;
        padding-right: 0px !important;
        > div {
            margin: 0;
            color: #FFFFFF;
            p{
                color: #FFFFFF !important;
                ${FontSize[11]};
                padding-right: 5px;
            }
        }
        input {
            border: none !important;
            padding-left: 0 !important;
            height: 26px !important;
        }
        button {
            padding: 0 !important;
            width: 20px !important;
            height: 20px !important;
            color: white;
            svg{
                width: 14px !important;
                height: 14px !important;
            }
        }
    }
    
    /* --------------------- */

    /* select end adornment */

    [class*="adornedEnd"] {
        > div[class*="select"] {
            margin: 0 !important;
            border: none !important;
            height: 26px !important;
            padding-right: 24px !important;
            padding-left: 0 !important;
        }
        > svg[class*="MuiSelect-icon"] {
            right: 16px !important;
        }
        > div[class*="positionEnd"] {
            margin: 0 3px 0 0 !important;
            color: white;
            width: 24px;
            small {
                ${FontSize[10]};
                color: rgba(255,255,255,.8);
            }
        }
    }
    
    /* ------------------- */
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
    }

    input[type=number] { -moz-appearance:textfield; }


`;

export { Input };


import React from 'react';
import { ThemeExcelAddIn } from '../../widgets'

import { FinancialExcelAddIn } from 'fs-widgets-core'
import Manual from "../../assets/pdf/Manual_de_usuario_addin_v1.0.pdf"
import FunctionsDoc from "../../assets/pdf/Monex_trader_REQ_Addin_v.2.0.pdf"
import Installer from "../../assets/zip/addin-monex.zip"
import Feature2 from "../../assets/images/feature-2.png"
import Feature1 from "../../assets/images/feature-1.png"
import Rics from "../../assets/images/rics.svg"
import Image2 from "../../assets/images/2.png"
import Image3 from "../../assets/images/3.png"
import Image4 from "../../assets/images/4.png"
import Image5 from "../../assets/images/5.png"
import Image6 from "../../assets/images/6.png"
import Image7 from "../../assets/images/7.png"
import Image8 from "../../assets/images/8.png"

const dataImg = [
    {
      image: Feature2,
      description: "Organiza y manipula la información adaptándola a tu flujo de trabajo.",
    },
    {
      image: Feature1,
      description: "Acceso fácil a contenido preciso.",
    },
    {
      image: Rics,
      description:
        "Obtén información en tiempo real e históricos a cerca de: Bonos, Cetes, Acciones Nacionales, etc...",
    },
  ];
  
  const data = [
    {
      step: "1",
      label: "Abrir Excel y Seleccionar libro en blanco",
      image: Image2,
    },
    {
      step: "2",
      label: "En menú Archivo selecciona “Opciones”",
      image: Image3,
    },
    {
      step: "3",
      label: "Ve a la opción “Complementos”",
      image: Image4,
    },
    {
      step: "4",
      label: "En la parte de abajo “Administrar”,  Seleccionar: “Ir...”",
      image: Image5,
    },
    {
      step: "5",
      label: "Muestra ventana de “Complementos” y presionar botón “Examinar” ",
      image: Image6,
    },
    {
      step: "6",
      label:
        'Busca la ruta donde este tu descarga del documento “FSAddin-AddIn” y presiona "Aceptar"',
      image: Image7,
    },
    {
      step: "7",
      label:
        "Activar la opción del complemento FSAddIn-AddIn y hacer clic en “Aceptar”",
      image: Image8,
    },
  ];

export const ExcelAddIn = () => {

    return (
        <ThemeExcelAddIn>
            <FinancialExcelAddIn
            dataAccordion={data}
            dataImage={dataImg}
            installerFile={Installer}
            manual={Manual}
            functionsDoc={FunctionsDoc}
            note="Para cuentas con licencia de Office Corporativas, Microsoft Office bloquea la ejecución de complementos externos. Por lo que si tu cuenta es corporativa, deberás contactar a tu administrador de Office para habilitar el uso del complemento Add In."
            />
        </ThemeExcelAddIn>
    )
}
import { type FC } from 'react'
import {ContentTicker} from "./style"
import TickerPropTypes from "./interface"
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';

const ThemeTicker: FC<TickerPropTypes> = (props) => {
    const {children} = props
    const theme = useAppSelector(currentTheme);
    return(
        <ContentTicker theme={theme}>
            {children}
        </ContentTicker>
    )

}

export default ThemeTicker;


import React, { type FC } from 'react'
import {Wrapper} from "./style"
import PropsInterface from "./interface"
import { changeTheme } from "../../../../theme/hooks/themeSlice";
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { currentTheme } from '../../../../theme/hooks/themeSlice';
import { availableLangs, currentLang, setLanguage } from "../../../../lang/langSlice";
import HeaderDrawer from "../headerDrawer"
import {
    AtomicText,
    AtomicButtonIcon
} from "../../../../atom"
import { X, CheckCircle } from 'react-feather';
import { ILanguage } from '../../../../lang/interface/language.model';

const ConfigOptions: FC<PropsInterface> = (props) => {
    const dispatch = useAppDispatch();
    const theme = useAppSelector(currentTheme);
    const lang = useAppSelector(currentLang);
    const languages = useAppSelector(availableLangs)
    const{
        open,
        onClose
    } = props

    const handleTheme = (color: 'dark'|'ligth') => () => {
        dispatch(changeTheme(color));
    }
   
    return(
        <Wrapper open={open}>
            <HeaderDrawer
                titleDrawer='Configuración'
                description='Selecciona las siguientes opciones'
                onClose={onClose}
            />
            <AtomicText  size="11" textnowrap="true" mt={1} bold>Color:</AtomicText>
            <div className='content-checks'>
                <label className="sg-Radio sg-Radio-btn">
                    <input onClick={handleTheme('dark')} type='radio' name='radiobtns' value='dark' className="sg-Radio-input" checked={theme.key === 'dark'}/>
                    
                    <span className="sg-Radio-text dark">
                        <CheckCircle className="sg-Radio-icon"/>
                        Dark
                    </span>
                </label>
                <label className="sg-Radio sg-Radio-btn">
                    <input onClick={handleTheme('ligth')} type='radio'  name='radiobtns' value='ligth' className="sg-Radio-input" checked={theme.key === 'ligth'}/>
                    
                    <span className="sg-Radio-text ligth">
                        <CheckCircle className="sg-Radio-icon"/>
                        Ligth
                    </span>
                </label>
            </div>
        </Wrapper>
    )
}

export default ConfigOptions;


import { type FC } from 'react'
import {Text} from './style';
import TextPropsProperties from './interface';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';


let theme = createTheme();
theme = responsiveFontSizes(theme);

const AtomicText: FC<TextPropsProperties> = (props) => {
    
    const {
        fontFamily,
        color,
        children,
        variant,
        component,
        ml,
        mt,
        mr,
        mb,
        textcenter,
        textright,
        nomargin,
        textnowrap,
        size,
        bold
    } = props

    return (
        <ThemeProvider theme={theme}>
            <Text
                fontFamily={fontFamily ? fontFamily : 'primary'}
                color={color}
                variant={variant}
                component={component}
                ml={ml}
                mt={mt}
                mr={mr}
                mb={mb}
                bold={bold}
                textcenter={textcenter}
                textright={textright}
                nomargin={nomargin}
                textnowrap={textnowrap}
                size={size}
            >
                {children}
            </Text>
        </ThemeProvider>
    )
}

export default AtomicText;
import React, { useEffect, useState, type FC } from 'react'
import { ContentBolet } from "./style"
import PropTypes from "./interface"
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';
import { currentLang } from '../../lang/langSlice';
import { getEquitiesOrders, getFundsOrders, selectContracts, selectCurrentContract, selectCurrentUser, selectEquitiesState, selectFundsState, setContract } from '../../trader/traderSlice';
import { FinancialBolet } from 'fs-widgets-core';
import { updateTermsService, getInfoTermsService, getBusinessDays } from '../../trader/traderAPI';
import moment from 'moment';
import ContractModel from '../../trader/interface/contract.model';

interface DateOptions {
    minDate: Date;
    maxDate: Date;
    excludeDates: Date[];
}

const ThemeBolet: FC<PropTypes> = ({children}) => {
    // const { instance } = props
    const theme = useAppSelector(currentTheme);
    // const lang = useAppSelector(currentLang);
    // const user = useAppSelector(selectCurrentUser);
    // const contracts = useAppSelector(selectContracts);
    // const contract = useAppSelector(selectCurrentContract);
    // const {
    //     listFunds,
    //     selectedFund,
    // } = useAppSelector(selectFundsState);
    // const {
    //     selectedEquity
    // } = useAppSelector(selectEquitiesState);
    // const [dateOptions, setDateOptions] = useState<DateOptions>({
    //     minDate: new Date(),
    //     maxDate: new Date(),
    //     excludeDates: []
    // });
    // const dispatch = useAppDispatch();
    // const handleGetInfoTerms = (alias: string) => {
    //     (async () => {
    //         const base64 = await getInfoTermsService(alias);
    //         if (!base64) {
    //             return;
    //         }
    //         const byteCharacters = atob(base64);
    //         const byteNumbers = new Array(byteCharacters.length);
    //         for (let i = 0; i < byteCharacters.length; i++) {
    //             byteNumbers[i] = byteCharacters.charCodeAt(i);
    //         }
    //         const byteArray = new Uint8Array(byteNumbers);
    //         const file = new Blob([byteArray], { type: 'application/pdf;base64' });
    //         const fileURL = URL.createObjectURL(file);
    //         window.open(fileURL);

    //     })();
    // }
    // const handleUpdateContractTerms = (alias: string, contractId: string) => {
    //     updateTermsService(alias, contractId);
    // }
    // useEffect(() => {
    //     (async () => {
    //         const dates = await getBusinessDays();
    //         if (dates.length === 0) {
    //             return;
    //         }
    //         const dateSet = new Set(dates);
    //         const minDate = moment(dates[0], "YYYY-MM-DD").toDate();
    //         const maxDate = moment(dates[dates.length - 1], "YYYY-MM-DD").toDate();
    //         const excludeDates = new Array<Date>();
    //         let date = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate() + 1);
    //         while (date < maxDate) {
    //             if (!dateSet.has(moment(date).format('YYYY-MM-DD'))) {
    //                 excludeDates.push(date);
    //             }
    //             date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    //         }
    //         setDateOptions({
    //             minDate,
    //             maxDate,
    //             excludeDates
    //         });
    //     })();
    // }, []);
    // const onChangeContract = (payload: ContractModel) => {
    //     dispatch(setContract(payload));
    // }
    // const onCreateEquityOrder = () => {
    //     if(user?.alias && contract?.id) {
    //         const payload = {
    //             alias: user.alias,
    //             contractId: contract.id
    //         }
    //         dispatch(getEquitiesOrders(payload));
    //     }
    // }
    // const onCreateFundOrder = () => {
    //     if(user?.alias && contract?.id) {
    //         const payload = {
    //             alias: user.alias,
    //             contractId: contract.id
    //         }
    //         dispatch(getFundsOrders(payload));
    //     }
    // }
    return (
        <ContentBolet theme={theme}>
            {children}
            {/* <FinancialBolet
                instance={`${instance}`}
                url=""
                language={lang.code}
                alias={user?.alias}
                onGetInfoTerms={handleGetInfoTerms}
                onUpdateContractTerms={handleUpdateContractTerms}
                dateOptions={dateOptions}
                contracts={contracts}
                currentContract={contract}
                onChangeContract={onChangeContract}
                funds={listFunds}
                selectedEquity={selectedEquity}
                selectedFund={selectedFund}
                onCreateEquityOrder={onCreateEquityOrder}
                onCreateFundOrder={onCreateFundOrder}
            /> */}
        </ContentBolet>
    )
}

export default ThemeBolet;


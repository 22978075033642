import styled from "styled-components";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const DatePickerStyle = styled(DesktopDatePicker) <any>`
    font-family: Raleway;
    color: #FFFFFF !important;
    [class*="adornedEnd"] {
        color: white !important;
    }
`;

export { DatePickerStyle };
import { createSlice } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

export interface SettingsState {
  showMenu: boolean;
}

const initialState: SettingsState = {
  showMenu: true
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleShowMenu: (state) => {
      state.showMenu = !state.showMenu;
    }
  }
})

export const { toggleShowMenu } = settingsSlice.actions;

export const selectShowMenu = (state: RootState) => state.settings.showMenu;


export default settingsSlice.reducer;